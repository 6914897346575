(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DeliveryConfigController', DeliveryConfigController);

    DeliveryConfigController.$inject = ['$scope', 'LocationProvice', 'LocationCity', '$state','LocationDistrict','MessageService', 'System', 'Qinius', '$q', '$timeout','DeliverySettings'];

    function DeliveryConfigController ($scope, LocationProvice, LocationCity, $state,LocationDistrict,MessageService,System, Qinius, $q, $timeout,DeliverySettings) {
        var vm = this;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.deliveryConfig = {};

        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        init()

        function init(){
            DeliverySettings.get(function(res){
                vm.deliveryConfig = res
                getAddressData()
            })
        }

        $scope.saveExpress = function(){
            if(vm.province) vm.deliveryConfig.province = vm.province.name
            if(vm.city) vm.deliveryConfig.city = vm.city.name
            if(vm.district) vm.deliveryConfig.district = vm.district.name
            DeliverySettings.update(vm.deliveryConfig, function onSaveSuccess () {
                vm.isSaving = false;
                MessageService.success("保存成功");
                init()
            }, function onSaveError () {
                vm.isSaving = false;
                MessageService.error("保存失败，请稍后重试！");
            })

        }

        // 获取地址数据
        function getAddressData(){
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if ( vm.deliveryConfig.province && p.name == vm.deliveryConfig.province) {
                        vm.province = p;
                        break;
                    }
                }
                vm.districts = [];
                if(vm.province){
                    LocationCity.findByProvince({provinceId: vm.province.id}, function (data) {
                        vm.citys = data;
                        console.log(data);
                        for (var i = vm.citys.length - 1; i >= 0; i--) {
                            var c = vm.citys[i];
                            if (vm.deliveryConfig.city&&  c.name == vm.deliveryConfig.city) {
                                vm.city = c;
                                break;
                            }
                        }
                        if(vm.city){
                            LocationDistrict.findByCities({cityId: vm.city.id}, function (data) {
                                vm.districts = data;
                                for (var i = vm.districts.length - 1; i >= 0; i--) {
                                    var di = vm.districts[i];
                                    if (vm.deliveryConfig.district && di.name == vm.deliveryConfig.district) {
                                        vm.district = di;
                                        // vm.address = res.orderLocation.address.split(vm.district.name+"-")[1];
                                    }
                                }
                            });
                        }
                    });
                }
            });
        }

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }

    }
})();
