(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('Module', Module);

    Module.$inject = ['$resource'];

    function Module ($resource) {
        /*模块的列表配置*/
        var modules = [
            {
                name:"订单中心",
                icon:"lf_1gs.png",
                id:"order",
                auth:['ROLE_SELF_SUPPORT_ORDER', 'ROLE_GENERAL_ORDER', 'ROLE_REPLENISHMENT_ORDER'],
                menus:[
                    {
                        name:"定制订单",
                        id:"orderSelf",
                        auth:["ROLE_SELF_SUPPORT_ORDER"]
                    },
                    {
                        name:"通品订单",
                        id:"orderGeneral",
                        auth:["ROLE_SELF_SUPPORT_ORDER"]
                    },
                    // {
                    //     name: "外部订单",
                    //     id: "orderExternal",
                    //     auth: ["ROLE_SELF_SUPPORT_ORDER"]
                    // },
                    {
                        name:"补货订单",
                        id:"orderReplenish",
                        auth:["ROLE_REPLENISHMENT_ORDER"]
                    },
                    {
                        name:"预警订单",
                        id:"orderWarn",
                        auth:["ROLE_REPLENISHMENT_ORDER"]
                    }
                     //{
                     //    name:"VIP订单",
                     //    id:"orderVip",
                     //    auth:["ROLE_VIP_ORDER"]
                     //},
                     //{
                     //    name:"第三方订单",
                     //    id:"traceOrder",
                     //    auth:["ROLE_THIRD_ORDER"]
                     //}
                ]
            },
            {
                name:"项目中心",
                icon:"lf_4cp.png",
                id:"projectCenter",
                auth:['ROLE_PROJECT'],
                menus:[
                    {
                        name:"项目列表",
                        id:"project",
                        auth:["ROLE_PROJECT"]
                    }
                ]
            },
            // {
            //     name:"人工排版",
            //     icon:"lf_rg.png",
            //     id:"manualTask",
            //     auth:['ROLE_TASK_ALLOCATION','ROLE_MY_TASK'],
            //     menus:[
            //         {
            //             name:"任务分配",
            //             id:"taskAllocation",
            //             auth:["ROLE_TASK_ALLOCATION"]
            //         },
            //         {
            //             name:"我的任务",
            //             id:"myTasks",
            //             auth:["ROLE_MY_TASK"]
            //         }
            //     ]
            // },
            {
                name:"产品中心",
                icon:"lf_4cp.png",
                id:"product",
                auth:['ROLE_CLASSIFY','ROLE_PRODUCT', 'ROLE_MATERIAL', 'ROLE_MATERIALTEMPLATE','ROLE_ITEM'],
                menus:[
                    {
                        name:"分类管理",
                        id:"classify",
                        auth:["ROLE_CLASSIFY"]
                    },
                    {
                        name:"定制品管理",
                        id:"commodity",
                        parameter:{productType:'OrderingProduct'},
                        auth:["ROLE_PRODUCT"]
                    },
                    {
                        name:"成品管理",
                        id:"commodity1",
                        parameter:{productType:'GeneralProduct'},
                        auth:["ROLE_PRODUCT"]
                    },
                    {
                        name:"辅料管理",
                        id:"commodity2",
                        parameter:{productType:'MaterialProduct'},
                        auth:["ROLE_MATERIAL"]
                    },
                    {
                        name:"物料模版",
                        id:"materials",
                        auth:['ROLE_MATERIALTEMPLATE']
                    },
                    {
                        name:"属性管理",
                        id:"item",
                        auth:['ROLE_ITEM']
                    }
                ]
            },
            {
                name:"生产中心",
                icon:"outsourcingTask.png",
                id:"outsourcingCentre",
                auth:['ROLE_ENTRUST','ROLE_IMPOSITION','ROLE_PREPRESS','ROLE_OUTPAINT'],
                menus:[
                    {
                        name:"任务列表",
                        id:"outsourcingTask",
                        auth:["ROLE_ENTRUST"]
                    },
                    {
                        name:"拼版列表",
                        id:"makeUp",
                        auth:["ROLE_IMPOSITION"]
                    },
                    {
                        name:"印前任务列表",
                        id:"prePress",
                        auth:["ROLE_PREPRESS"]
                    },
                    {
                        name:"扩边任务列表",
                        id:"expandTask",
                        auth:["ROLE_OUTPAINT"]
                    }
                ]
            },
            {
                name:"供应商中心",
                icon:"lf_6gy.png",
                id:"supply",
                auth:['ROLE_SUP'],
                menus:[
                    {
                        name:"供应商管理",
                        id:"supplyManagement",
                        auth:["ROLE_SUP"]
                    },
                    {
                        name:"供应商api",
                        id:"supplyApi",
                        auth:["ROLE_SUP"]
                    }
                ]
            },
            // {
            //     name:"客户中心",
            //     icon:"lf_6gy.png",
            //     id:"customerHub",
            //     auth:['ROLE_SUP'],
            //     menus:[
            //         {
            //             name:"客户管理",
            //             id:"customerHub",
            //             auth:["ROLE_SUP"]
            //         },
            //         {
            //             name:"开票申请管理",
            //             id:"invoicing",
            //             auth:["ROLE_SUP"]
            //         }
            //     ]
            // },
            {
                name:"成品仓库",
                icon:"warehouse.png",
                id:"finishedWarehouse",
                auth:['ROLE_PRODUCT_WAREHOUSE', 'ROLE_INVENTORY_WAREHOUSE', 'ROLE_ALLOCATION_WAREHOUSE', 'ROLE_OUTIN_WAREHOUSE'],
                menus:[
                    {
                        name:"库存查询",
                        id:"reserve",
                        auth:["ROLE_PRODUCT_WAREHOUSE"]
                    },
                    {
                        name:"盘点调整记录",
                        id:"inventory",
                        auth:["ROLE_INVENTORY_WAREHOUSE"]
                    },
                    {
                        name:"调拨记录",
                        id:"allocation",
                        auth:["ROLE_ALLOCATION_WAREHOUSE"]
                    },
                    {
                        name:"出入库记录",
                        id:"outgoingAndWarehousing",
                        auth:["ROLE_OUTIN_WAREHOUSE"]
                    }
                ]
            },
            {
                name:"辅料仓库",
                icon:"warehouse.png",
                id:"accessoriesWarehouse",
                auth:['ROLE_MATERIAL_WAREHOUSE', 'ROLE_MATERIAL_INVENTORY', 'ROLE_MATERIAL_ALLOCATION', 'ROLE_MATERIAL_OUTIN'],
                menus:[
                    {
                        name:"库存查询",
                        id:"reserve2",
                        auth:["ROLE_MATERIAL_WAREHOUSE"]
                    },
                    {
                        name:"盘点调整记录",
                        id:"inventory2",
                        auth:["ROLE_MATERIAL_INVENTORY"]
                    },
                    {
                        name:"调拨记录",
                        id:"allocation2",
                        auth:["ROLE_MATERIAL_ALLOCATION"]
                    },
                    {
                        name:"出入库记录",
                        id:"outgoingAndWarehousing2",
                        auth:["ROLE_MATERIAL_OUTIN"]
                    }
                ]
            },
            {
                name:"发货中心",
                icon:"lf_2dd.png",
                id:"invoiceCentre",
                auth:['ROLE_DELIVER'],
                menus:[
                    {
                        name:"发货列表",
                        id:"invoice",
                        auth:["ROLE_DELIVER"]
                    },
                    {
                        name:"发货请求",
                        id:"deliveryRequest",
                        auth:["ROLE_DELIVER"]
                    }
                ]
            },
            {
                name:"采购管理",
                icon:"lf_6gy.png",
                id:"purchase",
                auth:['ROLE_PURCHASE', 'ROLE_PURCHASE_LIST'],
                menus:[
                    {
                        name:"采购需求",
                        id:"purchasingApplication",
                        auth:["ROLE_PURCHASE"]
                    },
                    {
                        name:"采购单",
                        id:"purchaseProductLine",
                        auth:["ROLE_PURCHASE_LIST"]
                    }
                ]
            },
            {
                name:"盘点管理",
                icon:"lf_6gy.png",
                id:"stockManagement",
                auth:[ 'ROLE_STOCK_TAKING_ORDER'],
                menus:[
                    {
                        name:"盘点单",
                        id:"stockManagement",
                        auth:["ROLE_STOCK_TAKING_ORDER"]
                    }
                ]
            },
            {
                name:"数据统计",
                icon:"lf_11sj.png",
                id:"dataChart",
                auth:[ 'ROLE_STOCK_TAKING_ORDER'],
                menus:[
                    {
                        name:"生产统计",
                        id:"orderStatistics",
                        auth:["ROLE_STOCK_TAKING_ORDER"]
                    }
                ]
            },
            {
                name:"数据统计",
                icon:"lf_11sj.png",
                id:"chart",
                auth:['ROLE_DATA_STATISTICS', 'ROLE_MERCHANT_CHART', 'ROLE_SUPPLIER_DOWNLOAD_CHART'],
                menus:[
                    {
                        name:"供应商数据",
                        id:"chart",
                        auth:['ROLE_DATA_STATISTICS']
                    },
                    {
                        name:"商户数据",
                        id:"merchant-chart",
                        auth:['ROLE_MERCHANT_CHART']
                    },
                    {
                        name:"下载数据",
                        id:"supplier-download-chart",
                        auth:['ROLE_SUPPLIER_DOWNLOAD_CHART']
                    },
                    {
                        name:"补货数据",
                        id:"replenishment-chart",
                        auth:['ROLE_SUPPLIER_DOWNLOAD_CHART']
                    },
                    {
                        name:"发货数据",
                        id:"deliver-chart",
                        auth:['ROLE_SUPPLIER_DOWNLOAD_CHART']
                    }
                ]
            },
            {
                name:"用户中心",
                icon:"lf_9yh.png",
                id:"user",
                auth:['ROLE_ROLE','ROLE_USER'],
                menus:[
                    {
                        name:"角色管理",
                        id:"role",
                        auth:['ROLE_ROLE']
                    },
                    {
                        name:"用户管理",
                        id:"user",
                        auth:['ROLE_USER']
                    }
                ]
            },
            {
                name:"设置中心",
                icon:"lf_10ct.png",
                id:"setting",
                auth:['ROLE_GOODS_SHELVES', 'ROLE_SET_SUP', 'ROLE_REPLENISH_REASON', 'ROLE_EXPRESS', 'ROLE_WAREHOUSE', 'ROLE_UNIT', 'ROLE_CHANNEL',
                'ROLE_PRICE', 'ROLE_SET_INTER', 'ROLE_SYSTEM_SETTING',  'ROLE_SYSTEM_LOG', 'ROLE_SET_MAKEUP', 'ROLE_MACHINE', 'ROLE_EXPRESS_API', 'ROLE_DELIVERY_SETTING'],
                menus:[
                    {
                        name:"货架管理",
                        id:"shelfManagement",
                        auth:['ROLE_GOODS_SHELVES']
                    },
                    // {
                    //     name:"商户管理",
                    //     id:"merchant",
                    //     auth:['ROLE_SET_SUP']
                    // },
                    {
                        name:"拼版管理",
                        id:"makeup",
                        auth:['ROLE_SET_MAKEUP']
                    },
                    {
                        name:"补货原因",
                        id:"replenishmentReason",
                        auth:['ROLE_REPLENISH_REASON']
                    },
                    {
                        name:"快递设置",
                        id:"express",
                        auth:['ROLE_EXPRESS']
                    },
                    {
                        name:"快递鸟渠道设置",
                        id:"expressApiConfig",
                        auth:['ROLE_EXPRESS_API']
                    },
                    {
                        name:"发货信息配置",
                        id:"deliveryConfig",
                        auth:['ROLE_DELIVERY_SETTING']
                    },
                    {
                        name:"仓库管理",
                        id:"depository",
                        auth:['ROLE_WAREHOUSE']
                    },
                    {
                        name:"计量单位",
                        id:"unitMeasurement",
                        auth:['ROLE_UNIT']
                    },
                    {
                        name:"机型管理",
                        id:"machine",
                        auth:['ROLE_MACHINE']
                    },
                    {
                        name:"渠道管理",
                        id:"channel",
                        auth:['ROLE_CHANNEL']
                    },
                    {
                        name:"价目表",
                        id:"priceList",
                        auth:['ROLE_PRICE']
                    },

                    {
                        name:"接口配置",
                        id:"interface",
                        auth:['ROLE_SET_INTER']
                    },
                    {
                        name:"系统设置",
                        id:"system",
                        auth:['ROLE_SYSTEM_SETTING']
                    },
                    {
                        name:"印前管理",
                        id:"prePressFlow",
                        auth:['ROLE_SYSTEM_SETTING']
                    },
                    {
                        name:"文件校验",
                        id:"proofRule",
                        auth:['ROLE_SYSTEM_SETTING']
                    },
                    // {
                    //     name:"支付设置",
                    //     id:"payment-settings",
                    //     auth:['ROLE_SYSTEM_SETTING']
                    // },
                    {
                        name:"系统日志",
                        id:"systemLog",
                        auth:['ROLE_SYSTEM_LOG']
                    },
                    {
                        name:"消息通知",
                        id:"inform",
                        auth:['ROLE_SYSTEM_LOG']
                    },
                    {
                        name:"标签列表",
                        id:"tag",
                        auth:['ROLE_SYSTEM_LOG']
                    }

                ]
            },
            {
                name:"出入库管理",
                icon:"warehouse.png",
                id:"inventoryManagement",
                auth:['ROLE_INVENTORY_STOCK_OUT','ROLE_INVENTORY_STOCK_DIFF','ROLE_INVENTORY_STOCK_RETURN'],
                menus:[
                    {
                        name:"出库管理",
                        id:"outBound",
                        auth:['ROLE_INVENTORY_STOCK_OUT']
                    },
                    {
                        name:"门店入库差异",
                        id:"outBoundDifference",
                        auth:['ROLE_INVENTORY_STOCK_DIFF']
                    },
                    {
                        name:"门店退货管理",
                        id:"storeRefundGoods",
                        auth:['ROLE_INVENTORY_STOCK_RETURN']
                    },
                    // {
                    //     name:"入库管理",
                    //     id:"inBound",
                    //     auth:['ROLE_ROLE']
                    // },
                ]
            },
        ];
        return modules;
    }
})();
