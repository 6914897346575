(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ExpandTaskController', ExpandTaskController);

        ExpandTaskController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','$uibModal','MessageService', 'ParseLinks', 'Supplier','OutpaintFileTasks', 'Product', '$localStorage','$http', '$location', 'DateUtils'];

    function ExpandTaskController ($scope, Principal, LoginService, $state,User,$uibModal,MessageService, ParseLinks, Supplier,OutpaintFileTasks, Product, $localStorage, $http, $location, DateUtils) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_OUTPAINT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });

        vm.token = $localStorage.authenticationToken;

        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];

        vm.stateList = [
            {name:'待分配', value:'ToBeAssigned'},
            {name:'已分配', value: 'Assigned'},
            {name:'生产中', value: 'Producing'},
            {name:'被冻结', value: 'Freeze'},
            {name:'外发失败', value: 'OutFailed'}
        ];

        vm.conditionList = [
            {name:'全部', value:''},
            {name:'处理中', value:'PROCESSING'},
            {name:'已处理', value: 'FINISHED'},
            {name:'处理异常', value: 'FAILED'},
        ];
        vm.isFilter = false;

        vm.predicate = 'createdDate';
        vm.resolve = false;

        vm.tableData = {
            sort: [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')],
            page: Number($location.search().page) || 0,
            size: 20,
            state: $location.search().state || '',
            startDate: $location.search().startDate || '',
            endDate: $location.search().endDate || '',
            productId: $location.search().productId || '',
            keyword: $location.search().keyword || ''
        };
        angular.element("#startDate").val($location.search().startDate || GetDateStr(-31));
        angular.element("#endDate").val($location.search().endDate || GetDateStr(0));
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        Product.getAllByCondition({items:[{key:"category",op:"=",value:'GeneralProduct'}]},function (res){
            vm.productList = res.filter(function(item){return item.enable;});
            if(vm.tableData.productId != ''){
                for(var i=0; i<vm.productList.length; i++ ){
                    if(vm.productList[i].id == vm.tableData.productId){
                        vm.product = vm.productList[i];
                    }
                }
            }
        });

        $scope.selectAll = function () {
            if($scope.select_all) {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = true;
                })
                $scope.checked = vm.salesOrders.map(function(value){
                    return value.id;
                })
            }else {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = false;
                    $scope.checked = [];
                })
            }
        };
        $scope.selectProduct =function(data){
            if(data){
                vm.tableData.productId = data.id;
            }else {
                vm.tableData.productId = "";
            }
        };
        $scope.selectOne = function () {
            angular.forEach(vm.salesOrders , function (item) {
                var index = $scope.checked.indexOf(item.id);
                if(item.checked && index === -1) {
                    $scope.checked.push(item.id);
                } else if (!item.checked && index !== -1){
                    $scope.checked.splice(index, 1);
                };
            });

            if (vm.salesOrders.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }
        };

        $scope.sort = function () {
            vm.tableData.sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            init();
        }

        init();
        function init() {
            $scope.checked = [];
            $scope.select_all = false;
            $location.search({
                'page':vm.tableData.page,
                'state':vm.tableData.state,
                'startDate':vm.tableData.startDate,
                'endDate':vm.tableData.endDate,
                'makeupId': vm.tableData.makeupId,
                'back': vm.tableData.back,
                'productId': vm.tableData.productId,
                'keyword': vm.tableData.keyword
            });
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            var _data = {
                page: vm.tableData.page,
                size: 20,
                items: [
                    {key: 'createdDate', op: '>', value: DateUtils.dayStart(vm.tableData.startDate)},
                    {key: 'createdDate', op: '<=', value: DateUtils.dayEnd(vm.tableData.endDate)},
                    {key: 'salesOrder.number', op: 'like', value: vm.tableData.keyword},
                    {key: 'salesOrder.propertyConfig.product.id', op: '=', value: vm.tableData.productId},
                    {key: 'status', op: '=', value: vm.tableData.state},
                ]
            }
            OutpaintFileTasks.byCondition(_data, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.salesOrders = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.conditionSelect = function(data){
            vm.tableData.state = data.value;
            vm.tableData.page = 0;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.reset = function(){
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                state:'',
                startDate: '',
                endDate: '',
                productId: ''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            vm.supplier = "";
            vm.product = "";
            init();
        };

        //下载
        $scope.orderExport = function () {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            $http({
                url: 'api/outpaint-file-tasks/exportByCondition',
                method: "POST",
                data: {
                    page: vm.tableData.page,
                    size: 20,
                    items: [
                        {key: 'createdDate', op: '>', value: DateUtils.dayStart(vm.tableData.startDate)},
                        {key: 'createdDate', op: '<=', value: DateUtils.dayEnd(vm.tableData.endDate)},
                        {key: 'salesOrder.number', op: 'like', value: vm.tableData.keyword},
                        {key: 'salesOrder.propertyConfig.product.id', op: '=', value: vm.tableData.productId},
                        {key: 'status', op: '=', value: vm.tableData.state},
                    ]
                },
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = '扩边任务' + Date.now() + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (error) {
            });
        };


        $scope.status = function(target) {
            switch (target) {
                case "PENDING":
                    return "待处理";
                case "PROCESSING":
                    return "处理中";
                case "FINISHED":
                    return "已处理";
                case "FAILED":
                    return "处理异常";
                default:
                    return "没有对应的状态";
            }
        }
    }
})();
