(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchaseOrderEditController', PurchaseOrderEditController);

    PurchaseOrderEditController.$inject = ['$scope','$state', '$q', 'MessageService', 'Reserves', 'PurchaseProductLine', 'Supplier', 'Depository', 'UnitMeasurement', 'Product', 'PurchaseOrder', 'entity','$uibModal'];

    function PurchaseOrderEditController ($scope, $state, $q, MessageService, Reserves, PurchaseProductLine, Supplier, Depository, UnitMeasurement, Product, PurchaseOrder, entity,$uibModal) {
        var vm = this;
        vm.productAdd = productAdd;
        vm.productDelete = productDelete;
        vm.getWarehouse = getWarehouse;
        vm.types = [
            {name:'成品采购', value:'Outsourcing'},
            {name:'材料采购', value:'RawMaterial'}
        ];

        vm.purchaseOrder = entity;
        vm.suppliers = Supplier.getAll();
        vm.update = true;
        $q.all([vm.purchaseOrder.$promise,vm.suppliers.$promise]).then(function(){
            if(vm.purchaseOrder.status != 'New'){
                vm.update = false;
            }
            init();
        });

        function init() {
            if(vm.purchaseOrder.type =='Outsourcing'){
                vm.warehousis = Depository.getAll({type: 'product'});
            }else if(vm.purchaseOrder.type =='RawMaterial'){
                vm.warehousis = Depository.getAll({type: "accessories"});
            }else{
                vm.warehousis = Depository.getAll({type: null});
            }

            vm.deliveryDate = vm.purchaseOrder.deliveryDate == null ? null : vm.purchaseOrder.deliveryDate.substr(0, 10);
            $q.all([vm.warehousis.$promise]).then(function(){
                getProduct(function (){
                    if(vm.purchaseOrder.purchaseProductLines && vm.purchaseOrder.purchaseProductLines.length>0){
                        vm.purchaseOrder.purchaseProductLines.forEach(function (line){
                            if(vm.purchaseOrder.status == 'AlreadyIssued' && !line.warehousingNum){
                                line.warehousingNum = line.num;
                            }
                            $scope.selectPurchaseProduct(line);
                            if(!line.propertyConfigId && line.propertyConfigSku && line.propertyConfigs && line.propertyConfigs.length>0){
                                for(var i=0; i<line.propertyConfigs.length; i++){
                                    if(line.propertyConfigSku == line.propertyConfigs[i].sku){
                                        line.propertyConfigId=line.propertyConfigs[i].id;
                                        break
                                    }
                                }
                            }
                        })
                    }
                })
            });
        }
        function getProduct(callback){
            if(vm.purchaseOrder.type == 'Outsourcing'){
                vm.products = Product.getAllByCondition({items:[{key:"category",op:"=",value:'GeneralProduct'}]});
            }else{
                vm.products = Product.getAllByCondition({items:[{key:"category",op:"=",value:'MaterialProduct'}]});
            }
            if(callback){
                $q.all([vm.products.$promise]).then(function(){
                    callback();
                });
            }
        }


        $scope.selectStatus = function(){
            if(vm.purchaseOrder.type =='Outsourcing'){
                vm.warehousis = Depository.getAll({type: 'product'});
            }else if(vm.purchaseOrder.type =='RawMaterial'){
                vm.warehousis = Depository.getAll({type: "accessories"});
            }else{
                vm.warehousis = Depository.getAll({type: null});
            }
            getProduct(function (){
                vm.purchaseOrder.purchaseProductLines = [];
            })
        };

        //去掉选中入库之后的sku null
        $scope.selectPurchaseProduct = function(data){
            data.propertyConfigs=[];
            data.propertyConfigId=null;
            if(data.productId){
                for(var i=0; i<vm.products.length; i++){
                    if(data.productId == vm.products[i].id){
                        data.propertyConfigs=vm.products[i].propertyConfigs;
                        data.measurementName=vm.products[i].measurementName;
                        data.measurementId=vm.products[i].measurementId;
                        data.productName=vm.products[i].name;
                        break
                    }
                }
            }
        };
        //
        $scope.selectPurchaseProductSku = function(data,index,item){
            var hasSelectedArr =  vm.purchaseOrder.purchaseProductLines.filter(function(item){
                return item.propertyConfigId === data.propertyConfigId
            }) || []
            if(hasSelectedArr.length > 1){
                MessageService.error("该sku已存在 请重新选择 ");
                data.propertyConfigId = ''
                return
            }
        };

        function productAdd() {
            if(!vm.purchaseOrder.type){
                MessageService.error("请选择采购类型");
                return
            }
            if (vm.purchaseOrder.purchaseProductLines == undefined) {
                vm.purchaseOrder.purchaseProductLines = [];
            }
            var purchaseProductLine = {
                productId:null,
                num: 0,
                price: 0,
                tax: vm.taxRate || 0,
                propertyConfigId:null,
                propertyConfigs: [],
            };
            vm.purchaseOrder.purchaseProductLines.push(purchaseProductLine);
        }

        function productDelete(index) {
            if(vm.purchaseOrder.purchaseProductLines[index].id){
                vm.purchaseOrder.purchaseProductLines[index].cancelled = true;
            }else{
                vm.purchaseOrder.purchaseProductLines.splice(index, 1);
            }
        }

        // 默认入库，需要考虑商品的类型
        function getWarehouse() {
            if(vm.purchaseOrder.warehouseId){
                vm.warehousis.forEach(function (item){
                    if(item.id == vm.purchaseOrder.warehouseId){
                        vm.purchaseOrder.warehouseName = item.name;
                        vm.purchaseOrder.warehouseType = item.type;
                        vm.purchaseOrder.contacts = item.contacts;
                        vm.purchaseOrder.phone = item.phone;
                        vm.purchaseOrder.address = item.province + item.city + item.district + item.address;
                    }
                })
            }else{
                vm.purchaseOrder.warehouseName = null;
                vm.purchaseOrder.warehouseType = null;
                vm.purchaseOrder.contacts = null;
                vm.purchaseOrder.phone = null;
                vm.purchaseOrder.address = null;
            }
        }

        function getDate(strDate) {
            var st = strDate;
            var a = st.split(" ");
            var b = a[0].split("-");
            var c = a[1].split(":");
            var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
            return date;
        }

        $scope.save = function(saveAndSubmit,callback){
            if(vm.isSaving){return }
            if (!vm.purchaseOrder.supplierId) {
                MessageService.error("请选择供应商");
                return false;
            }
            if (vm.purchaseOrder.type == null || vm.purchaseOrder.type == '') {
                MessageService.error("请选择采购类型");
                return false;
            }

            var deliveryDate = getDate($('#startDate').val() + " 23:59:59");
            vm.purchaseOrder.deliveryDate = deliveryDate;
            if (vm.purchaseOrder.deliveryDate == null || vm.purchaseOrder.deliveryDate == '') {
                MessageService.error("请选择计划交期");
                return false;
            }
            if (!vm.purchaseOrder.warehouseId) {
                MessageService.error("请选择默认入库");
                return false;
            }
            if (vm.purchaseOrder.contacts == null || vm.purchaseOrder.contacts == '') {
                MessageService.error("请输入联系人");
                return false;
            }
            if (vm.purchaseOrder.phone == null || vm.purchaseOrder.phone == '') {
                MessageService.error("请输入联系电话");
                return false;
            }
            if (vm.purchaseOrder.address == null || vm.purchaseOrder.address == '') {
                MessageService.error("请输入收货地址");
                return false;
            }
            if (vm.purchaseOrder.freight === null || vm.purchaseOrder.freight === '' || vm.purchaseOrder.freight < 0) {
                MessageService.error("请输入运费");
                return false;
            }
            if(vm.purchaseOrder.purchaseProductLines && vm.purchaseOrder.purchaseProductLines.length>0){
                var purchaseProductLines = [];
                vm.purchaseOrder.purchaseProductLines.forEach(function (item){
                    if(!item.cancelled){
                        purchaseProductLines.push(item)
                    }
                })
                if (purchaseProductLines.length == 0) {
                    MessageService.error("商品信息不能为空");
                    return false;
                }
                for (var i = 0; i < vm.purchaseOrder.purchaseProductLines.length; i++) {
                    var purchaseProductLine = vm.purchaseOrder.purchaseProductLines[i];
                    if(!purchaseProductLine.cancelled){
                        if (!purchaseProductLine.productId || !purchaseProductLine.propertyConfigId) {
                            MessageService.error("商品信息中的商品选择不完整");
                            return false;
                        }
                        if (purchaseProductLine.num == null || purchaseProductLine.num <= 0) {
                            MessageService.error("请输入商品信息中的数量");
                            return false;
                        }
                        if (purchaseProductLine.price == null) {
                            MessageService.error("请输入商品信息中的单价");
                            return false;
                        }

                        if (purchaseProductLine.tax === null || purchaseProductLine.tax === '' || purchaseProductLine.tax < 0) {
                            MessageService.error("请输入商品信息中的税率");
                            return false;
                        }
                        vm.purchaseOrder.purchaseProductLines[i].total = (purchaseProductLine.num * purchaseProductLine.price).toFixed(2);
                        vm.purchaseOrder.purchaseProductLines[i].taxPrice = (purchaseProductLine.price * purchaseProductLine.tax / 100) + purchaseProductLine.price;
                        vm.purchaseOrder.purchaseProductLines[i].taxTotal = (((purchaseProductLine.price * purchaseProductLine.tax / 100) + purchaseProductLine.price) * purchaseProductLine.num).toFixed(2);
                    }
                }

            }else {
                MessageService.error("商品信息不能为空");
                return false;
            }

            vm.isSaving = true;
            if(saveAndSubmit){
                PurchaseOrder.saveAndSubmit(vm.purchaseOrder, function(res){
                    vm.isSaving = false;
                    MessageService.success('新建采购成功！');
                    $scope.cancelSave();
                },function(error){
                    vm.isSaving = false;
                    MessageService.error('新建采购失败');
                });
            }else{
                if(vm.purchaseOrder.status == 'AlreadyIssued'){
                    PurchaseOrder.changeWarehousingNum(vm.purchaseOrder, function(res){
                        vm.isSaving = false;
                        MessageService.success('保存成功！');
                        $scope.cancelSave();
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('编辑采购失败');
                    });
                    return
                }
                if(vm.purchaseOrder.id){
                    PurchaseOrder.update(vm.purchaseOrder, function(res){
                        vm.isSaving = false;
                        if(callback){
                            callback()
                        }else{
                            MessageService.success('编辑采购成功！');
                            $scope.cancelSave();
                        }
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('编辑采购失败');
                    });
                }else{
                    PurchaseOrder.save(vm.purchaseOrder, function(res){
                        vm.isSaving = false;
                        if(callback){
                            callback()
                        }else{
                            MessageService.success('新建采购成功！');
                            $scope.cancelSave();
                        }
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('新建采购失败');
                    });
                }
            }
        };

        $scope.cancelSave = function() {
            history.back();
        }

        $scope.getSupplier = function() {
            vm.purchaseOrder.supplierName = null;
            vm.taxRate = 0;
            if(vm.purchaseOrder.supplierId){
                vm.suppliers.forEach(function (item){
                    if(item.id == vm.purchaseOrder.supplierId){
                        vm.purchaseOrder.supplierName =item.name;
                        vm.taxRate = item.taxRate;
                    }
                })
            }
            if(vm.purchaseOrder.purchaseProductLines && vm.purchaseOrder.purchaseProductLines.length>0){
                vm.purchaseOrder.purchaseProductLines.forEach(function (item){
                    item.tax = vm.taxRate;
                })
            }
        }
        //通过或拒绝
        $scope.audit = function (item){
            if(!item){
                MessageService.confirm("是否审核拒绝", function () {
                    PurchaseOrder.changeState({ids:vm.purchaseOrder.id,state:'Close'}, function(res){
                        if(res.state == 400){
                            MessageService.error(res.message || '拒绝失败');
                            return
                        }
                        MessageService.success('已拒绝');
                        $scope.cancelSave();
                    },function(error){
                        MessageService.error('拒绝失败');
                    });
                });
            }else{
                MessageService.confirm("确认审核通过", function () {
                    PurchaseOrder.changeState({ids:vm.purchaseOrder.id,state:'AlreadyIssued'}, function(res){
                        if(res.state == 400){
                            MessageService.error(res.message || '通过失败');
                            return
                        }
                        MessageService.success('已通过');
                        $scope.cancelSave();
                    },function(error){
                        MessageService.error('通过失败');
                    });
                });
            }
        }
        //关闭
        $scope.close = function (){
            MessageService.confirm("确认关闭当前采购单", function () {
                PurchaseOrder.changeState({ids:vm.purchaseOrder.id,state:'Close'}, function(res){
                    if(res.state == 400){
                        MessageService.error(res.message || '拒绝失败');
                        return
                    }
                    MessageService.success('已拒绝');
                    $scope.cancelSave();
                },function(error){
                    MessageService.error('拒绝失败');
                });
            });
        }
        $scope.numChange = function (purchaseProductLine){
            purchaseProductLine.warehousingNum = purchaseProductLine.num;
            console.log(purchaseProductLine)
        }
        //登记入库
        $scope.changeWarehousingNum = function (data){
            $uibModal.open({
                templateUrl: 'app/purchase/warehousing.html',
                controller: 'WarehousingController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return {
                            purchaseOrder:vm.purchaseOrder,
                            purchaseOrderList:data
                        };
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.purchaseOrder = result;
                    init();
                }
            });
        }
        //完成入库
        $scope.constraint = function (){
            MessageService.confirm("确认完成入库", function () {
                vm.isSaving = true;
                PurchaseOrder.changeWarehousingNum(vm.purchaseOrder, function(res){
                    PurchaseOrder.changeState({ids: vm.purchaseOrder.id, state: 'compulsoryWarehousing'}, function (data) {
                        MessageService.success('采购订单完成入库成功！');
                        $scope.cancelSave();
                        vm.isSaving = false;
                    }, function (error) {
                        MessageService.error('采购订单完成入库失败！');
                        vm.isSaving = false;
                    });
                },function(error){
                    vm.isSaving = false;
                    MessageService.error('编辑采购失败');
                });
            });
        }
        $scope.purchaseOrderType = function (item){
            switch (item) {
                case 'New':
                    return {
                        text:'待提交',
                        color:'#333'
                    };
                case 'ToBeAudited':
                    return {
                        text:'待审核',
                        color:'#ffa037'
                    };
                case 'ToBeRevised':
                    return {
                        text:'已关闭',
                        color:'#333'
                    };
                case 'AlreadyIssued':
                    return {
                        text:'未入库',
                        color:'#ff0000'
                    };
                case 'Store':
                    return {
                        text:'部分入库',
                        color:'#51de37'
                    };
                case 'Finished':
                    return {
                        text:'已完成',
                        color:'#005eff'
                    };
                case 'Examined':
                    return {
                        text:'审核通过',
                        color:'#333'
                    };
                default:
                    return {
                        text:item,
                        color:'#333'
                    };
            }
        }
    }
})();
