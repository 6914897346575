(function () {
    'use strict';

    angular
        .module('gwApp')
        .controller('PriceListAddController', PriceListAddController);

    PriceListAddController.$inject = ['$scope', '$state', 'MessageService', 'Machine', '$uibModal', 'Channels', 'Product', 'Prices', 'Supplier', 'CustomerHub', 'ProductCategory'];

    function PriceListAddController($scope, $state, MessageService, Machine, $uibModal, Channels, Product, Prices, Supplier, CustomerHub, ProductCategory) {
        var vm = this;
        init();

        function init() {
            vm.price = {
                name: '',
                priceType: 'channel',
                supplierId: null,
                customerHubId: null,
                channelId: null,
                priceLines: [],
                enabled: true
            };
            //获取渠道
            Channels.getAll(function (res) {
                vm.channels = res.filter(function (item) {
                    return item.enable;
                });
            });

            //供应商
            Supplier.getAll("", function (res) {
                vm.suppliers = res.filter(function (item) {
                    return item.enable;
                });
            });

            //客户
            CustomerHub.getAll("", function (res) {
                vm.customerHubs = res.filter(function (item) {
                    return item.enable;
                });
            });

            // 一级分类
            ProductCategory.byCondition({
                items: [{key: 'subordinate', op: '=', value: false}, {
                    key: 'enable',
                    op: '=',
                    value: true
                }]
            }, function (res) {
                vm.productCategoryFirst = res;
            });
        }

        vm.priceTypes = [
            {name: '供应商价目表', value: 'supplier'},
            // {name: '客户价目表', value: 'customer'},
            {name: '渠道价目表', value: 'channel'}
        ];

        $scope.choosePriceType = function () {
            vm.price.supplierId = null;
            vm.price.customerHubId = null;
            vm.price.channelId = null;
        }

        //新增规则
        var priceLine = {
            addPage: false,
            productCategoryFirstId: null,//一级分类
            // productCategoryFirstName:null,
            productCategorySecondId: null,//二级分类
            // productCategorySecondName:null,
            productId: null,
            // productName:null,
            // priceId:null,
            priceRules: []
        };

        $scope.priceLineNew = function () {
            var flag = false;
            if(vm.price.priceLines.length >0){
                angular.forEach(vm.price.priceLines,function(item){
                    if(!item.productCategoryFirstId || !item.productId){
                        flag = true;
                    }
                });
            }
            if(flag){
                MessageService.error('请将空白项填写完整再添加！！！！');
            }else {
                vm.price.priceLines.push(angular.copy(priceLine));
            }
        }

        // 设置sku
        $scope.defaultSet = function (data) {
            $uibModal.open({
                templateUrl: 'app/setting/priceList/priceList-sku.html',
                controller: 'PriceListSkuController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'mg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return data;
                    }
                }
            }).result.then(function (result) {
                data.priceRules = result;
            });
        }

        $scope.changeCategoryFirst = function (priceLine, index) {
            priceLine.productCategorySecondId = null;
            priceLine.products = [];

            ProductCategory.byCondition({
                items: [{key: 'subordinate', op: '=', value: true},
                    {key: 'enable', op: '=', value: true},
                    {key: 'productCategory.id', op: '=', value: priceLine.productCategoryFirstId}]
            }, function (res) {
                priceLine.productCategorySecond = res;
                if(!res.length){
                    //获取商品
                    Product.byProductCategoryId({productCategoryId:priceLine.productCategoryFirstId},function (res) {
                        priceLine.products = res.filter(function (item) {
                            return item.enable;
                        });
                    });
                }
            });
        }
        $scope.changeCategorySecond = function (priceLine, data) {
            Product.byProductCategoryId({productCategoryId:priceLine.productCategorySecondId},function (res) {
                priceLine.products = res.filter(function (item) {
                    return item.enable;
                });
            });
        }

        $scope.changeProduct = function (priceLine) {
            priceLine.priceRules = [];
            angular.forEach(priceLine.products,function (pro) {
                if(priceLine.productId==pro.id){
                    angular.forEach(pro.propertyConfigs,function (p) {
                        var rule = {
                            propertyConfigId: p.id,
                            propertyConfig:p,
                            type:'STANDARD',
                            baseOn:'NUMBER',
                            ladderSetups:[],
                            priceNum:null,
                            vipPrice:null,
                            propertyConfigSku:null,
                            piecePrice:null,
                            vipPiecePrice:null,
                        }
                        priceLine.priceRules.push(rule);
                    })
                }
            })
            // priceLine.productId = priceLine.product.id;
        }


        $scope.defaultDelete = function (index) {
            vm.price.priceLines.splice(index, 1);
        };

        $scope.save = function () {
            if(!vm.price.name){
                MessageService.error("价目表名称不能为空");
                return;
            }
            if(vm.price.name.length >20){
                MessageService.error("价目表名称不能大于20位");
                return;
            }
            if(!vm.price.priceType){
                MessageService.error("请选择类型");
                return;
            }
            if(vm.price.priceType=='supplier'&&(vm.price.supplierId==null||vm.price.supplierId=='')){
                MessageService.error("请选择所属供应商");
                return;
            }
            if(vm.price.priceType=='customer'&&(vm.price.customerHubId==null||vm.price.customerHubId=='')){
                MessageService.error("请选择所属客户");
                return;
            }
            if(vm.price.priceType=='channel'&&(vm.price.channelId==null||vm.price.channelId=='')){
                MessageService.error("请选择所属渠道");
                return;
            }
            var ccFlag = true;
            if (vm.price.priceLines != null && vm.price.priceLines.length > 0) {
                angular.forEach(vm.price.priceLines, function (item,i) {
                    if (!item.productCategoryFirstId) {
                        MessageService.error("一级分类不能为空！");
                        ccFlag = false;
                        return;
                    }
                    if (!item.productId) {
                        MessageService.error("请选择第"+(i-0+1)+"行商品");
                        ccFlag = false;
                        return;
                    }
                    if (vm.price.priceRules == null && vm.price.priceLines.length == 0) {
                        MessageService.error("商品SKU不能为空");
                        ccFlag = false;
                        return;
                    }
                });
            }else{
                MessageService.error("价目规则不能为空");
                return;
            }
            if(!ccFlag){
                return;
            }
            console.log(vm.price);
            // return
            vm.isSaving = true;
            Prices.create(vm.price, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("priceList");
            }

            function onSaveError() {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
