(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ChannelAddController', ChannelAddController);

    ChannelAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'Channels', '$uibModal'];

    function ChannelAddController ($scope, Principal, LoginService, $state,User,MessageService,Channels, $uibModal) {
        var vm = this;
        vm.data = {
            name: '',
            enable: true
        };
        vm.isSaving = false;

        //选择产品
        $scope.chooseProduct = function(){
            $uibModal.open({
                templateUrl: 'app/setting/channel/chooseProduct.html',
                controller: 'CannelChooseProductController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return vm.data.propertyConfigs || [];
                    }]
                }
            }).result.then(function(result){
                console.log(result);
                if(result!=undefined){
                    vm.data.propertyConfigs = result;
                }else{
                    vm.data.propertyConfigs = [];
                }
            });
        };

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("渠道名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("渠道名称不能大于20位");
                return;
            }
            if(!vm.data.url){
                MessageService.error("渠道url不能为空");
                return;
            }
            if(!vm.data.makeupUrl){
                MessageService.error("拼版url不能为空");
                return;
            }
            vm.isSaving = true;
            Channels.add(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("channel");
            }

            function onSaveError (error) {
                console.log(error)
                vm.isSaving = false;
                MessageService.error(error.data.message);
            }
        };

        //偏远地区
        $scope.productionSet = function(){
            $uibModal.open({
                templateUrl: 'app/setting/channel/province/province.html',
                controller: 'CProvinceController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        // $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return vm.data;
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.data.remoteProvinces = result.remoteProvinces;
                    vm.data.freight = result.freight;
                }
            });
        };
        //返回
        $scope.goBack = function () {
            $state.go("channel");
        }
    }
})();
