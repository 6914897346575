(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ImportStockUpload', ImportStockUpload);

        ImportStockUpload.$inject = ['$timeout', '$q',  '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ListService',
     'MessageService',  'Upload', ];

    function ImportStockUpload($timeout, $q,  $scope, $stateParams, $uibModalInstance, entity, ListService, MessageService,  Upload) {
        var vm = this;

        vm.coupon = entity;
        console.log(entity,$stateParams.type);
        vm.clear = clear;
        vm.save = save;
        vm.warehouseType=$stateParams.type?$stateParams.type:''
        function clear() {
            $uibModalInstance.close();
        }
        window.updateFile = function ($event) {
        };
        function save() {
            if(vm.isSaving){
                return
            }
            vm.isSaving = true;
            var file = document.getElementById("fileId").files[0];

            var getBase64 = function (file, callback) {
                var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                reader.onload = function () { //  成功读取
                    $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                };
                reader.onerror = function (error) { //  出错
                };
            };
            getBase64(file, function (result) {});
            Upload.upload({
                type: 'POST',
                url: 'api/reserves/import?warehouseType=' + vm.warehouseType, //上传路径
                data: {
                    file: file,
                    name: file.name
                }
            }).then(function successCallback(res) {
                vm.isSaving = false;
                if(res.status && res.status == 200){
                    MessageService.success("操作成功");
                    $uibModalInstance.close(res.data.message);
                }
            }, function errorCallback(response) {
                vm.isSaving = false;
                $uibModalInstance.close();
                MessageService.error(response.data.message || "文件导入失败，请稍后重试");
            });
        }
    }
})();
