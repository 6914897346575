(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('orderWarn', {
                parent: 'module',
                url: '/orderWarn',
                params:{page: null,state: null,source: null,startDate: null,endDate: null,back: null,timeWarning: null,replenishment: null,refund: null,supplierId: null,productId: null,keyword: null,productCategoryId:null, sku:null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-warn.html',
                        controller: 'OrderWarnController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('orderSelf', {
                parent: 'module',
                url: '/orderSelf',
                params:{page: null,state: null,source: null,startDate: null,endDate: null,back: null,timeWarning: null,replenishment: null,refund: null,supplierId: null,productId: null,keyword: null,productCategoryId:null, sku:null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-self.html',
                        controller: 'OrderSelfController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('orderGeneral', {
                parent: 'module',
                url: '/orderGeneral',
                params:{page: null,state: '',source: null,startDate: null,endDate: null,back: null,timeWarning: null,replenishment: null,refund: null,supplierId: null,productId: null,keyword: null,productCategoryId:null, sku:null,number:null,logisticsNumber:null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-general.html',
                        controller: 'OrderGeneralController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })

            .state('ordergGeneralDetails', {
                parent: 'module',
                url: '/ordergGeneralDetails/{id}',
                params:{page: null,state: null,source: null,startDate: null,endDate: null,back: null,timeWarning: null,replenishment: null,refund: null,supplierId: null,productId: null,keyword: null,productCategoryId:null, sku:null,number:null,logisticsNumber:null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-general-details.html',
                        controller: 'OrderGeneralDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('orderExternal', {
                parent: 'module',
                url: '/orderExternal',
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-external.html',
                        controller: 'OrderExternalController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('orderReplenish', {
                parent: 'module',
                url: '/orderReplenish',
                params:{page: null,state: null,source: null,startDate: null,endDate: null,back: null,keyword: null,productCategoryId:null, sku:null},
                data: {
                    authorities: ['ROLE_REPLENISHMENT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-replenish.html',
                        controller: 'OrderReplenishController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('orderDetails', {
                parent: 'module',
                url: '/orderDetails/{id}',
                params:{page: null,state: null,source: null,startDate: null,endDate: null,back: null,timeWarning: null,replenishment: null,refund: null,supplierId: null,productId: null,keyword: null,productCategoryId:null, sku:null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-details.html',
                        controller: 'OrderDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }],
                    entity: ['SalesOrder','$stateParams', function(SalesOrder, $stateParams) {
                        return SalesOrder.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('orderExtemalDetails', {
                parent: 'module',
                url: '/orderExtemalDetails/{id}',
                params:{page: null,state: null,source: null,startDate: null,endDate: null,back: null,timeWarning: null,replenishment: null,refund: null,supplierId: null,productId: null,keyword: null,productCategoryId:null, sku:null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/order-extemal-details.html',
                        controller: 'OrderExtemalDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }],
                    entity: ['SalesOrder','$stateParams', function(SalesOrder, $stateParams) {
                        return SalesOrder.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('orderVipDetails', {
                parent: 'module',
                url: '/orderVipDetails/{id}',
                data: {
                    authorities: ['ROLE_VIP_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/order/orderVip-details.html',
                        controller: 'OrderVipDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
