(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('StockManagement', StockManagement)

    StockManagement.$inject = ['$resource'];

    function StockManagement ($resource) {
        var resourceUrl =  'api/stock-taking-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'submit': {
                method: 'POST',
                params:{
                    path:"submit"
                },
            },
            'close': {
                method: 'GET',
                params:{
                    path:"close"
                },
            },
            "byCondition":{
                method:"POST",
                params:{
                    id:"byCondition"
                },
                isArray: true
            },
            'get': { method: 'GET'},
            'getWarehouseStatus': {
                method: 'GET',
                params:{
                    path:"warehouse-taking"
                },
            },

        });
    };
})();
