(function() {
    'use strict';
    angular
        .module('gwApp')
        .controller('ImportStockUploadListController', ImportStockUploadListController);

        ImportStockUploadListController.$inject = ['$state','$stateParams','$uibModal','$scope'];

    function ImportStockUploadListController($state,$stateParams,$uibModal,$scope ) {

        var vm = this;
        vm.loadAll = loadAll
        loadAll();
        $scope.goBack = function(){
            history.back();
        }

        function loadAll(){
            $uibModal.open({
                templateUrl: 'app/reserveUpload/importStock-upload.html',
                controller: 'ImportStockUpload',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            quantity: null,
                            status: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(data) {
                vm.branchStores=data
            }, function() {
                // $state.go('^');
            });
        }
    }
})();
