(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils ($filter) {

        var service = {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertLocalDateFromServer : convertLocalDateFromServer,
            convertLocalDateToServer : convertLocalDateToServer,
            dateformat : dateformat,
            dayStart: dayStart,
            dayEnd: dayEnd,
            dayMid: dayMid
        };

        return service;

        function convertDateTimeFromServer (date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer (date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer (date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        function dayStart(val) {
            if (!val) {
                return null;
            }
            var date = new Date(val);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        }

        function dayMid(val) {
            if (!val) {
                return null;
            }
            var date = new Date(val);
            date.setHours(12);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        }

        function dayEnd(val) {
            if (!val) {
                return null;
            }
            var date = new Date(val);
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            return date;
        }
    }

})();
