(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('classify', {
                parent: 'module',
                url: '/classify',
                data: {
                    authorities: ['ROLE_CLASSIFY']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/classify.html',
                        controller: 'ClassifyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('classifyAdd', {
                parent: 'module',
                url: '/classifyAdd',
                data: {
                    authorities: ['ROLE_CLASSIFY']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/classify-add.html',
                        controller: 'ClassifyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('commodity', {
                parent: 'module',
                url: '/commodity?productType',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity.html',
                        controller: 'CommodityController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('commodity1', {
                parent: 'module',
                url: '/commodity1?productType',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity.html',
                        controller: 'CommodityController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('commodity2', {
                parent: 'module',
                url: '/commodity2?productType',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity.html',
                        controller: 'CommodityController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('commodityAdd', {
                parent: 'module',
                url: '/commodityAdd?productType',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity-edit.html',
                        controller: 'CommodityEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function($stateParams, Product) {
                        return {
                            template: {},
                            name:'',
                            category: $stateParams.productType,
                            productCategoryId:'',
                            productCategoryName:'',
                            measurementId:'',
                            measurementName:'',
                            enable: false,
                            propertyConfigs: [],
                            producible: false,              //可生产
                            warningTime: "",                //预警时间
                            weight: "",                //预警时间
                            imgDpi: "",                     //DPI
                            toning: "",                     //调色
                            autoOutsourcing: false,         //自动流转
                            productCirculations: [],        //流转配置
                        };
                    }],
                }
            })
            .state('commodityEdit', {
                parent: 'module',
                url: '/commodityEdit/{id}',
                params:{page: null},
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/product/commodity-edit.html',
                        controller: 'CommodityEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function($stateParams, Product) {
                        return Product.validate({id: $stateParams.id});
                    }],
                }
            });
    }
})();
