(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('PurchaseOrder', PurchaseOrder)
        .factory('PurchaseProductLine', PurchaseProductLine);

    PurchaseOrder.$inject = ['$resource'];
    PurchaseProductLine.$inject = ['$resource'];

    function PurchaseOrder ($resource) {
        var resourceUrl =  'api/purchaseOrder/:path/:id';

        return $resource(resourceUrl, {}, {
            'changeWarehousingNum': {
                method: 'PUT',
                params: {
                    path: 'changeWarehousingNum'
                }
            },
            'saveAndSubmit': {
                method: 'POST',
                params: {
                    path: 'saveAndSubmit'
                }
            },
            'changeState': {
                method: 'GET',
                params: {
                    path: 'changeState'
                }
            },
            'createOrderByDemand': {
                method: 'POST',
                params: {
                    path: 'createOrderByDemand'
                }
            },
            'constructOrderByDemand': { method: 'GET',
                params: {
                    path: 'constructOrderByDemand'
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    id:"byCondition"
                },
                isArray: true
            },
            'query': { method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'cancelled': {
                method: 'GET',
                params: {
                    path: 'cancelled'
                }
            },
            'changeStatue': {
                method: 'GET',
                params: {
                    path: 'changeStatue'
                }
            },
            'createPurchaseOrder': {
                method: 'GET',
                params: {
                    path: 'createPurchaseOrder'
                }
            }
        });
    };

    function PurchaseProductLine ($resource) {
        var resourceUrl =  'api/purchaseProductLine/:path/:id';

        return $resource(resourceUrl, {}, {
            'cancelDemand': { method: 'GET',
                params: {
                    path: 'cancelDemand'
                }
            },
            'examineOrder': { method: 'GET',
                params: {
                    path: 'examineOrder'
                }
            },
            'demandPage': { method: 'GET',
                isArray: true,
                params: {
                    path: 'demandPage'
                }
            },
            'query': { method: 'GET',
                isArray: true,
                params: {
                    path: 'page'
                }
            },
            'changeState': {
                method: 'GET',
                params: {
                    path: 'changeState'
                }
            },
            'changeWarehousingNum': {
                method: 'GET',
                params: {
                    path: 'changeWarehousingNum'
                }
            },
            'cancelled': {
                method: 'GET',
                params: {
                    path: 'cancelled'
                }
            }
        });
    };
})();
