(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('DeliverySettings', DeliverySettings)

    DeliverySettings.$inject = ['$resource'];

    function DeliverySettings ($resource) {
        var resourceUrl =  'api/delivery-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            get:{
                method:'GET',
                params: {
                    path: 'get'
                },
            },
            'update': { method:'POST' },
        });
    }
})();
