(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DeliveryDialogController', DeliveryDialogController);

    DeliveryDialogController.$inject = ['$q', '$scope', 'OrderPack', 'isDetail', 'MessageService', 'Express', 'entity', '$uibModalInstance', 'DeliveryRequest'];

    function DeliveryDialogController ($q, $scope, OrderPack, isDetail, MessageService, Express, entity, $uibModalInstance, DeliveryRequest) {
        var vm = this;
        vm.data = entity;

        Express.getAll(function(res){
            vm.expressCompanies = res.filter(function(item){return item.enable});
        });
        //取消
        $scope.cancelRecord = function () {
            $uibModalInstance.close();
        }

        $scope.save = function () {
            if(!vm.deliveryComp){
                MessageService.error('请选择快递公司');
                return
            }
            if(!vm.logisticsNumber){
                MessageService.error('请输入快递单号');
                return
            }
            var lines = []
            vm.data.salesOrders.forEach(function(item){
                var obj = {
                    id:item.id,
                    warehouseId:item.warehouseId
                }
                lines.push(obj)
            })
            var _data =  {
                id:vm.data.id,
                logisticsNumber:vm.logisticsNumber,
                deliveryComp:vm.deliveryComp,
                lines:lines
            }
            OrderPack.delivery(_data, function (res) {
                MessageService.success("发货成功")
                $uibModalInstance.close(true);
            },function(error){
                MessageService.error(res.message || "发货失败");
            })
        }
    }
})();
