(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SetStandardPriceController', SetStandardPriceController);

    SetStandardPriceController.$inject = ['$scope','$uibModalInstance','MessageService', 'msgdata','Makeups'];

    function SetStandardPriceController ($scope,$uibModalInstance,MessageService,msgdata,Makeups) {
        var vm = this;
        vm.save = save;
        console.log(msgdata);
        vm.addPage = msgdata.addPage;
        vm.price = angular.copy(msgdata.data)

        $scope.cancel =function(){
            $uibModalInstance.dismiss();
        };

        function save () {
            if(vm.price.priceNum === '' || vm.price.priceNum === null){
                MessageService.error("标准价格不能为空！");
                return;
            }
            if(vm.addPage&&(vm.price.piecePrice == '' || vm.price.piecePrice == null)){
                MessageService.error("每加1P（标准价）不能为空！");
                return;
            }
            if(vm.price.vipPrice === '' || vm.price.vipPrice === null){
                MessageService.error("会员价不能为空！");
                return;
            }
            if(vm.addPage&&(vm.price.vipPiecePrice == '' || vm.price.vipPiecePrice == null)){
                MessageService.error("每加1P（会员价）不能为空！");
                return;
            }

            $uibModalInstance.close(vm.price);

        }
    }
})();
