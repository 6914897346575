(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OutIdSelectController', OutIdSelectController);

    OutIdSelectController.$inject = ['$scope', 'abutId', '$uibModalInstance', 'MessageService', 'Product', '$q'];

    function OutIdSelectController ($scope, abutId, $uibModalInstance, MessageService, Product, $q) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.abutId = abutId;

        $scope.suplierProductsHide = function () {
            vm.suplierProducts = false;
            if (vm.suplierProductsApex) {
                for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                    var left = vm.suplierProductsApex[i];
                    for (var j = left.specs.length - 1; j >= 0; j--) {
                        var product = left.specs [j];
                        if (product.selected) {
                            product.selected = false;
                        }
                    }
                }
            }
            vm.currentItemValueLine = {};
        }

        $scope.suplierProductsSearch = function () {
            if (!!vm.originSupplerProductsApex && !!vm.suplierProductsSearchQuery) {
                vm.suplierProductsApex = angular.copy(vm.originSupplerProductsApex);
                for(var i = 0 ; i< vm.suplierProductsApex.length; i++){
                    var apex = vm.suplierProductsApex[i];
                    if (apex.specs) {
                        apex.specs =  apex.specs.filter(function(p) {
                            if (p.specName.indexOf(vm.suplierProductsSearchQuery)>=0 || p.skuNumber.indexOf(vm.suplierProductsSearchQuery)>=0) {
                                return p;
                            }
                        });
                    }
                }
            }
            if (!vm.suplierProductsSearchQuery) {
                vm.suplierProductsApex = angular.copy(vm.originSupplerProductsApex );
            }
        }

        $scope.showTree = function (tree) {
            tree.show = !tree.show;
        }

        $scope.showLeft = function (left) {
            left.show = !left.show;
        }

        $scope.selectApex = function (apex) {
            for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                var ap = vm.suplierProductsApex[i];
                if (ap.productName == apex.productName) {
                    ap.hide = false;
                } else {
                    ap.hide = true;
                }

            }
        }

         $scope.productChooseOne = function (p) {
            if (!p.selected) {
                for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                    var apex = vm.suplierProductsApex[i];
                    for (var j = apex.specs.length - 1; j >= 0; j--) {
                        var product = apex.specs[j];
                        if (product.skuNumber != p.skuNumber) {
                            product.selected = false;
                        }
                    }
                }
            }
            p.selected = !p.selected;
        }

        $scope.suplierProductsConfirm = function () {
            vm.suplierProducts = false;
            for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                var left = vm.suplierProductsApex[i];
                for (var j = left.specs.length - 1; j >= 0; j--) {
                    var product = left.specs[j];
                    if (product.selected) {
                        product.selected = false;
                        vm.currentItemValueLine.supNumber = product.skuNumber;
                        return;
                    }
                }
            }
        }

        $scope.suplierProductsHide = function () {
            vm.suplierProducts = false;
            if (vm.suplierProductsApex) {
                for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                    var left = vm.suplierProductsApex[i];
                    for (var j = left.specs.length - 1; j >= 0; j--) {
                        var product = left.specs [j];
                        if (product.selected) {
                            product.selected = false;
                        }
                    }
                }
            }
            vm.currentItemValueLine = {};
        }


        Product.getCategoryAndProductDetails({supplierAbutSettingId: vm.abutId},function (res) {
            if(res.state==200  ){
                //左侧导航栏   Tree -- > categories --> products
                vm.supplierProductsTree = res.message;
                var data = res.message;
                vm.suplierProductsApex = []; //右侧表格
                angular.forEach(data,function (item) {
                    angular.forEach(item.categories,function (ca) {
                        angular.forEach(ca.products,function (pro,i) {
                            vm.suplierProductsApex.push(pro);
                        })
                    })
                })
                vm.originSupplerProductsApex = angular.copy(vm.suplierProductsApex);
            }
        }, function () {

        })
        

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            for (var i = vm.suplierProductsApex.length - 1; i >= 0; i--) {
                var left = vm.suplierProductsApex[i];
                for (var j = left.specs.length - 1; j >= 0; j--) {
                    var product = left.specs[j];
                    if (product.selected) {
                        $uibModalInstance.close(product.skuNumber);
                        return;
                    }
                }
            }
            $uibModalInstance.close();
            return;
        }
    }
})();
