(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('RelationPurchaseDialogTwoController', RelationPurchaseDialogTwoController);

    RelationPurchaseDialogTwoController.$inject = ['$scope','$state','Reserves','$uibModalInstance','MessageService','Inventory','$stateParams','entity'];

    function RelationPurchaseDialogTwoController ($scope, $state,Reserves,$uibModalInstance,MessageService,Inventory,$stateParams,entity) {
        var vm = this;
        //获取产品 、sku 仓库
        // Reserves.getAllReserveByType({type:'accessories'},function(res){
        //     vm.reserves =res;
        // });
        console.log(entity);
        if(entity) vm.pitch = entity
        vm.data = [
            {
                number:1,
                quantity:2,
                amount:4,
                remarks:'备注1',
                createdDate:'2024-04-29T06:09:37Z',
            },
            {
                number:2,
                quantity:2,
                amount:4,
                remarks:'备注2',
                createdDate:'2024-04-29T06:09:37Z',
            },
            {
                number:3,
                quantity:2,
                amount:4,
                remarks:'备注3',
                createdDate:'2024-04-29T06:09:37Z',
            },
        ]

        $scope.search = function(){
            MessageService.error(vm.number+"查询");
        };

        $scope.cancelRecord = function(){
            $uibModalInstance.close();
        };

        $scope.saveRecord = function(){
            $uibModalInstance.close(vm.pitch);
        };

    }
})();
