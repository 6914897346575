(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('AllocationAddController', AllocationAddController);

    AllocationAddController.$inject = ['$scope','$state','$uibModalInstance','MessageService','Reserves','Product','Allocations','Depository'];

    function AllocationAddController ($scope, $state,$uibModalInstance,MessageService,Reserves,Product,Allocations,Depository) {
        var vm = this;
        //type  accessories 辅料 / product 成品
        Depository.getAll({type: "product"},function(res){
            vm.depositories = res.filter(function(item){return item.defaulted});
        });

        Product.getAllByCondition({items:[{key:"category",op:"=",value:'GeneralProduct'}]},function (res){
            vm.products = res.filter(function(item){return item.enable});
        })

        $scope.getUseQuantity = function(data, propertyConfig){
            if(data ==null){
                vm.reserveQuantity =-1;
            }else{
                Reserves.getByWarehouseAndProductNumber({
                    warehouseId: data.id,
                    propertyConfigId: propertyConfig.id,
                    type:'product'
                },function(res){
                    vm.reserveQuantity = res.usableQuantity;
                },function(error){
                    vm.reserveQuantity =0;
                });
            }
        };

        $scope.selectProduct = function(data){
            vm.outWarehouse =null;
            vm.inWarehouse =null;
            vm.reserveQuantity =-1;
            vm.propertyConfig = data.propertyConfigs[0];
        };

        $scope.validate = function() {
            if (vm.product == null || vm.product == '') {
                MessageService.error('请选择商品');
                return false;
            }
            if (vm.propertyConfig == null || vm.propertyConfig == '') {
                MessageService.error('请选择SKU');
                return false;
            }
            if (vm.outWarehouse == null || vm.outWarehouse == "") {
                MessageService.error("请选择调出仓库");
                return false;
            };
            if (vm.inWarehouse == null || vm.inWarehouse == "") {
                MessageService.error("请选择调入仓库");
                return false;
            };
            if (vm.outWarehouse.id ==vm.inWarehouse.id) {
                MessageService.error("调入仓库和调出仓库一致，无效");
                return false;
            };
            if (!vm.num) {
                MessageService.error("请选择调整数量");
                return false;
            };
            if (vm.num > vm.reserveQuantity) {
                MessageService.error("调出库存不足！");
                return false;
            };
            if (!vm.reason) {
                MessageService.error("请选择调整原因");
                return false;
            };
            return true;
        };

        $scope.saveAllocation = function(){
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            var obj={
                inWarehouse:vm.inWarehouse,
                outWarehouse:vm.outWarehouse,
                measurement:{
                    id:vm.product.measurementId,
                    name:vm.product.measurementName
                },
                propertyConfig:vm.propertyConfig,
                type:'product',
                num:vm.num,
                reason:vm.reason
            };
            Allocations.create(obj,function (res) {
                MessageService.success("挑拨记录新建成功!");
                $uibModalInstance.close();
            },function (error) {
                vm.isSaving = false;
                MessageService.error("挑拨记录新建失败！");
            });
        };

        $scope.cancelRecord = function(){
            $uibModalInstance.close();
        };

    }
})();
