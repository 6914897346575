(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('outBound', {
                parent: 'module',
                url: '/outBound',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null, storeName: null},
                data: {
                    authorities: ['ROLE_INVENTORY_STOCK_OUT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/out-bound.html',
                        controller: 'OutBoundController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })
            .state('outBoundDetails', {
                parent: 'module',
                url: '/outBoundDetails/{id}',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null,storeName: null},
                data: {
                    authorities: ['ROLE_INVENTORY_STOCK_OUT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/out-bound-details.html',
                        controller: 'OutBoundDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('outBoundPreview', {
                parent: 'module',
                url: '/outBoundPreview/{id}',
                data: {
                    authorities: ['ROLE_INVENTORY_STOCK_OUT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/out-bound-preview.html',
                        controller: 'OutBoundPreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OutBound', function($stateParams, OutBound) {
                        return OutBound.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('outBoundDifference', {
                parent: 'module',
                url: '/outBoundDifference',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null, storeName: null},
                data: {
                    authorities: ['ROLE_INVENTORY_STOCK_DIFF']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/out-bound-difference.html',
                        controller: 'OutBoundDifferenceController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })
            .state('outBoundDifferenceDetails', {
                parent: 'module',
                url: '/outBoundDifferenceDetails/{id}',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null,storeName: null},
                data: {
                    authorities: ['ROLE_INVENTORY_STOCK_DIFF']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/out-bound-difference-details.html',
                        controller: 'OutBoundDifferenceDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('storeRefundGoods', {
                parent: 'module',
                url: '/storeRefundGoods',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null, storeName: null},
                data: {
                    authorities: ['ROLE_INVENTORY_STOCK_RETURN']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/store-refund-goods.html',
                        controller: 'StoreRefundGoodsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })
            .state('storeRefundGoodsDetails', {
                parent: 'module',
                url: '/storeRefundGoodsDetails/{id}',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null,storeName: null},
                data: {
                    authorities: ['ROLE_INVENTORY_STOCK_RETURN']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/store-refund-goods-details.html',
                        controller: 'StoreRefundGoodsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('inBound', {
                parent: 'module',
                url: '/inBound',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null, storeName: null},
                data: {
                    authorities: ['ROLE_ENTRUST']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/in-bound.html',
                        controller: 'InBoundController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })


            .state('inBoundNew', {
                parent: 'module',
                url: '/inBoundNew',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null,storeName: null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/in-bound-details.html',
                        controller: 'InBoundDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('inBoundDetails', {
                parent: 'module',
                url: '/inBoundDetails/{id}',
                params:{page: null,state: null,stockOrderNumber: null,startDate: null,endDate: null,back: null,number: null,storeName: null},
                data: {
                    authorities: ['ROLE_SELF_SUPPORT_ORDER']
                },
                views: {
                    'content': {
                        templateUrl: 'app/inventoryManagement/in-bound-details.html',
                        controller: 'InBoundDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }
})();
