(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ChooseProductSkuController', ChooseProductSkuController);

    ChooseProductSkuController.$inject = ['$q', '$scope','$uibModalInstance','MessageService','Product','ParseLinks','entity','ListService'];

    function ChooseProductSkuController ($q, $scope, $uibModalInstance,MessageService,Product,ParseLinks,entity,ListService) {
        var vm = this;
        vm.keyword ='';
        vm.data=entity.products || [];
        vm.multiple=entity.multiple || false;
        vm.productTypes=entity.productTypes;
        vm.productTypes2=entity.productTypes2;
        vm.productType2 = null;
        vm.categories = [
            {name:'成品', type:'GeneralProduct', categories:[]},
            {name:'辅料', type:'MaterialProduct', categories:[]},
        ];
        vm.categories.forEach(function (item) {
            if(vm.productTypes && vm.productTypes.indexOf(item.type)>-1){
                item.show = true;
            }
            if(vm.productTypes2 && vm.productTypes2.indexOf(item.type2)>-1){
                item.show = true;
            }
        })
        vm.transition = function(){
            init();
        }
        fetchData()
        function fetchData(){
            var items = [{key:"category",op:"=",value:'GeneralProduct'},{key:"category",op:"=",value:'MaterialProduct'}];
            Product.getAllByCondition({
                // items:items
            }, function (res, headers) {
                console.log(res);
                // vm.links = ParseLinks.parse(headers('link'));
                // vm.queryCount = headers('X-Total-Count');

                // for(var i=0;i<res.length;i++){
                //     for(var j=0;j<res[i].productCirculations.length; j++){
                //         if(res[i].productCirculations[j].defaulted){
                //             res[i].productCirculationsList = res[i].productCirculations[j];
                //         }
                //     }
                // }
                // vm.tableList = res;
                // var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                // vm.pagecount = pages; //得到总页数
                // //调用分页
                // laypage({
                //     cont: 'paging',
                //     curr: (vm.tableData.page + 1), //初始化当前页
                //     pages: pages,
                //     skip: true, //是否开启跳页
                //     first: false,
                //     last: false,
                //     limit: true,
                //     jump: function(obj, first){
                //         if (!first) {
                //             vm.tableData.page = obj.curr - 1;
                //             init();
                //         }
                // //     }
                // });
            }, function (error) {

            });
        }


        // Category.getAllByCondition({
        //     items: [{key: "cancelled", op: "=", value: false},{key: "usable", op: "=", value: "true"},{key: "type", op: "in", value: vm.productTypes}]
        // },function (res) {
        //     res.forEach(function (item) {
        //         var e = ListService.inList(item,vm.categories,["type"]);
        //         if(e){
        //             vm.categories[e.subscriptIndex].categories.push(item);
        //         }
        //     })
        //     for(var i=0; i<vm.categories.length; i++){
        //         if(vm.categories[i].show){
        //             vm.categories[i].selected = true;
        //             if(vm.categories[i].categories){
        //                 vm.categorieId = vm.categories[i].categories[0].id;
        //             }else{
        //                 vm.productType2 = vm.categories[i].type2;
        //             }
        //             init()
        //             break
        //         }
        //     }
        // });
        vm.page=0;
        vm.products = [];
        function init(){
            var items = [
                {key: "product.cancelled", op: "=", value: false},
                {key: "product.saleable", op: "=", value: true},
                {key: "cancelled", op: "=", value: false},
                {key: "enable", op: "=", value: true},
                ];

            if(vm.categorieId){
                items.push({key: "product.category.id", op: "=", value: vm.categorieId})
            }
            if(vm.productType2){
                items.push({key: "product.productType2", op: "=", value: vm.productType2})
            }
            if(vm.keyword){
                items.push({key: "product.name", op: "like", value: vm.keyword})
            }
            // ItemValue.byCondition({
            //     items: items,
            //     page: vm.page,
            //     size: 10,
            //     sort: ["id,asc"]
            // }, function (res,headers) {
            //     vm.links = ParseLinks.parse(headers('link'));
            //     vm.totalItems = headers('X-Total-Count');
            //     vm.queryCount = vm.totalItems;
            //     vm.products = res || [];
            // });
        }

        $scope.categorieClick = function (item) {
            vm.productType2 = null;
            vm.categorieId = item.id;
            vm.page=0;
            vm.products = [];
            vm.keyword = "";
            init()
        }
        $scope.categorieClick1 = function (item) {
            vm.productType2 = item.type2;
            vm.categorieId = null;
            vm.page=0;
            vm.products = [];
            vm.keyword = "";
            init()
        }
        $scope.search = function(){
            vm.page=0;
            vm.products = [];
            init()
        };
        $scope.productChoice = function(data){
            var e = ListService.inList(data,vm.data ,["id"]);
            if(e){
                vm.data.splice(e.subscriptIndex,1);
            }else{
                if(vm.multiple){
                    vm.data.push(data)
                }else{
                    vm.data = [data];
                }
            }
            console.log(vm.data)
        };
        $scope.getExist = function(data){
            var e = ListService.inList(data,vm.data ,["id"]);
            if(e){
                return true
            }else{
                return false
            }
        }
        $scope.save = function(){
            $uibModalInstance.close(vm.data);
        }
        $scope.cancel = function(){
            $uibModalInstance.close();
        };

    }
})();
