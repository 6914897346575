(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SkuMakeupController', SkuMakeupController);

    SkuMakeupController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'MessageService', 'Product', '$q', 'Machine', '$uibModal', 'PrePressFlow'];

    function SkuMakeupController ($scope, msgdata, $uibModalInstance, MessageService, Product, $q, Machine,  $uibModal, PrePressFlow) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;

        vm.skuManages = [];
        vm.product = Product.get({id: msgdata.productId});
        vm.abutType = msgdata.abutType;
        vm.supplierAbutSettingId = msgdata.supplierAbutSettingId;
        vm.prePressFlowList = PrePressFlow.getAll();

        vm.machineList = Machine.getAll();
        $q.all([vm.product.$promise, vm.machineList.$promise, vm.prePressFlowList.$promise]).then(function(){
            vm.product.propertyConfigs.sort(function(a, b) {
                return a.sort - b.sort
            })
            vm.propertyList = vm.product.propertyConfigs;
            vm.prePressFlowList = vm.prePressFlowList.filter(function(item) {
                return item.enable;
            });

            if (!!msgdata.skuManageList) {
                for (var i = msgdata.skuManageList.length - 1; i >= 0; i--) {
                    var skuM = msgdata.skuManageList[i];
                    for (var j = vm.machineList.length - 1; j >= 0; j--) {
                        if (vm.machineList[j].id == skuM.deviceId) {
                            var skuManage ={
                                propertyId: skuM.propertyId,
                                sku: skuM.sku,
                                deviceId: skuM.deviceId,
                                deviceType: skuM.deviceType,
                                makeupId: skuM.makeup.id,
                                makeupName: skuM.makeup.name,
                                capacity: skuM.capacity,
                                price: skuM.price,
                                maxBoxing: skuM.maxBoxing,
                                makeupList: vm.machineList[j].makeups,
                                outId: skuM.outId,
                                prePress:skuM.prePress,
                                prePressFlowId:skuM.prePressFlowId

                            };
                            vm.skuManages.push(skuManage);
                        }
                    }
                }
            }
        });

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            vm.isSaving = true;
            var skuManageList = [];
            for (var i = vm.skuManages.length - 1; i >= 0; i--) {
                var skuM = vm.skuManages[i];
                var skuManageObj ={
                    propertyId: skuM.propertyId,
                    sku:skuM.sku,
                    maxBoxing:skuM.maxBoxing,
                    deviceId: skuM.deviceId,
                    deviceType: skuM.deviceType,
                    capacity: skuM.capacity,
                    price: skuM.price,
                    outId: skuM.outId,
                    prePress:skuM.prePress,
                    prePressFlowId:skuM.prePressFlowId
                };
                if (skuM.makeupId) {
                    skuManageObj.makeup ={id: skuM.makeupId}
                }
                skuManageList.push(skuManageObj);
            }

            $uibModalInstance.close(skuManageList);
        }

        //添加商品管理
        $scope.addSkuManage = function(){
            vm.flag = false;
            angular.forEach(vm.skuManages, function(item){
                if(!item.makeupId || !item.capacity || !item.propertyId || !item.deviceId){
                    vm.flag = true;
                }
            });
            if(vm.flag){
                MessageService.error("请完善空白项，再追加");
            }else{
                angular.forEach(vm.propertyList , function(p){
                    p.selected = false;
                })
                for(var j = 0; j< vm.skuManages.length; j++){
                    var sku = vm.skuManages[j];
                    if (sku.cancelled) {
                        continue;
                    }
                    for(var i = 0; i< vm.propertyList.length; i++){
                        var property= vm.propertyList[i];
                        if (property.id == sku.propertyId) {
                            property.selected = true;
                        }
                    }
                }

                angular.forEach(vm.propertyList , function(p){
                    if (!p.selected) {
                        var skuManage ={
                            propertyId:p.id,
                            sku:p.sku,
                            deviceId:'',
                            deviceType:'',
                            makeupId:'',
                            makeupName:'',
                            maxBoxing:0,
                            capacity:'',
                            price: 0,
                            prePress:false,
                            prePressFlowId:null,
                            makeupList: [],
                        };
                        vm.skuManages.push(angular.copy(skuManage));
                    }
                })
            }
        };

        $scope.chooseMachine = function(skuManage) {
            for (var i = vm.machineList.length - 1; i >= 0; i--) {
                if (vm.machineList[i].id == skuManage.deviceId) {
                    skuManage.capacity = vm.machineList[i].capacity;
                    skuManage.makeupList = vm.machineList[i].makeups;
                }
            }
        }

        $scope.skuDelete = function(index){
            vm.skuManages.splice(index,1);
        };


        $scope.selectOutId = function(data){
            if (vm.supplierAbutSettingId == null) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/supply/out-id-select.html',
                controller: 'OutIdSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    abutId : function () {
                        return vm.supplierAbutSettingId;
                    }
                }
            }).result.then(function(result){
                data.outId = result;
            });
        };

    }
})();
