(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ChooseWarehouseDialogController', ChooseWarehouseDialogController);

    ChooseWarehouseDialogController.$inject = ['$q', '$scope', '$state', 'MessageService', 'Depository', '$uibModalInstance', 'StockManagement'];

    function ChooseWarehouseDialogController ($q, $scope, $state, MessageService, Depository, $uibModalInstance, StockManagement) {
        var vm = this;
        Depository.getAll({},function(res){
            // 仅展示有效的 内部仓库
            vm.warehouseList = res.filter(function(item){return item.defaulted && item.houseSource == 'Internal'});
        });

        $scope.cancelRecord = function () {
            $uibModalInstance.close();
        }

        $scope.save = function () {
            if(!vm.warehouseId){
                MessageService.error('请选择仓库');
                return
            }
            StockManagement.getWarehouseStatus({warehouseId:vm.warehouseId},function(res){
                console.log(res);
                if(res.stockTaking){
                    MessageService.error('该仓库已在盘点中，请勿重复操作。')
                }else{
                    $state.go('stockAdd',{warehouseId: vm.warehouseId});
                    $uibModalInstance.close(true);
                }
            },function(error){

            })
        }
    }
})();
