(function() {
'use strict';

    angular
        .module('gwApp')
        .controller('InBoundDetailsController', InBoundDetailsController);

    InBoundDetailsController.$inject = ['$scope', 'Principal', '$state', 'MessageService', '$localStorage', '$stateParams', '$rootScope', '$uibModal','OutBound','FindWarehouse'];

    function InBoundDetailsController ($scope, Principal,  $state, MessageService, $localStorage, $stateParams, $rootScope, $uibModal,OutBound,FindWarehouse) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.searchWarehouse = searchWarehouse;
        vm.data = {}
        vm.data.status = 'UNSUBMITTED'
        vm.selectedPurchase = '' //选中的采购单
        // init()

        function init(){
            OutBound.get({id : $stateParams.id},function(res){
                console.log(res);
                vm.data  = res
                // 默认选中当前商品的第一个仓库并查询库存
                if(vm.data.status === 'UnShippedOut'){
                    vm.data.lines.forEach(function(item,index){
                        item.warehouseId = item.warehouses[0].id
                        searchWarehouse(item)
                    })
                }

            },function(res){
                MessageService.error("查询失败，请重试！");
            })
        }

        //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    stockOrderNumber: $stateParams.stockOrderNumber,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    number: $stateParams.number,
                    storeName: $stateParams.storeName,
                });
            }else {
                history.back();
            }
        };
        // 出库
        $scope.outBound = function(){
            console.log(vm.data);
            if(valiData()){
                 MessageService.confirm("是否确认出库？", function () {
                    OutBound.outBound(vm.data, function () {
                        MessageService.success("出库成功");
                        history.back();
                }, function (error) {
                        MessageService.error("出库失败，请重试！");
                    });
                });
            }
        };

        // 关闭出库单
        $scope.close = function(){
            MessageService.confirm("是否确认关闭该出库单，关闭后无法撤销？", function () {
                OutBound.close({id:vm.data.id}, function () {
                    MessageService.success("关闭成功");
                    history.back();
                }, function (error) {
                    MessageService.error("关闭失败，请重试！");
                });
            });
        };

        // 查库存
        function searchWarehouse(item,index){
            // 仓库名赋值
            item.warehouses.forEach(function(itm){
                if(itm.id === item.warehouseId) item.warehouseName = itm.name
            })
            var params = {
                warehouseId:item.warehouseId,
                productNumber:item.scmNumber,
            }
            FindWarehouse.searchByidAndNumber(params, function (res) {
                if(res.message && res.message.usableQuantity !== null){
                    item.inventoryDesc = res.message.usableQuantity
                }else{
                    item.inventoryDesc = ''
                }
            }, function (error) {
                MessageService.error("查询失败，请重试！");
            });
        };

        // 出库数量是否大于库存
        $scope.checkNum = function(itm,num){
            if(!itm.inventoryDesc){
                MessageService.error("请先选择出库仓库");
                itm.outNum  = null
                return
            }
            if(num > itm.inventoryDesc){
                MessageService.error("出库数量不能大于库存数量");
                itm.outNum  = null
                return
            }
        };

        // 出库数量不能为空判断
        function valiData(){
            var flag = true
            vm.data.lines.forEach(function(item){
                if(flag){
                    if(item.outNum === null){
                        MessageService.error("出库数量不能为空")
                        flag = false
                    }
                }

            })
            return flag
        }


        //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    stockOrderNumber: $stateParams.stockOrderNumber,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    number: $stateParams.number,
                    storeName: $stateParams.storeName,
                });
            }else {
                history.back();
            }
        };

        // 关联采购单
        $scope.relationPurchase = function(){
            $uibModal.open({
                templateUrl: 'app/inventoryManagement/components/relation-purchase-dialog.html',
                controller: 'RelationPurchaseDialogTwoController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity:[function(){
                        return vm.data.number
                    }]
                }
            }).result.then(function(result){
                // loadAll();
                console.log(result);
                if(result){
                    vm.selectedPurchase = result
                    vm.data.number = result
                }
            });
        };

         //选择商品
         $scope.addProduct =function () {
            var products = [];
            if(vm.data.lines && vm.data.lines.length>0){
                vm.data.lines.forEach(function (itemLine){
                    products.push({
                        id: itemLine.itemValueLineId
                    })
                })
            }
            $uibModal.open({
                templateUrl: 'app/inventoryManagement/components/choose-product-sku.html',
                controller: 'ChooseProductSkuController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return {
                            multiple: true,
                            products:products,
                            productTypes:"GeneralProduct;MaterialProduct"
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    console.log(res);
                    var lines = angular.copy(vm.data.lines);
                    vm.data.lines = [];
                    res.forEach(function (item){
                        // var e = ListService.inList2(item,vm.data.itemLines ,["id"],["itemValueLineId"]);
                        // if(!e){
                        //     var e1 = ListService.inList2(item,_itemLines ,["id"],["itemValueLineId"]);
                        //     if(e1){
                        //         vm.data.itemLines.push(e1);
                        //     }else{
                        //         vm.data.itemLines.push({
                        //             unit:item.unit,
                        //             quantity:0,
                        //             requireQuantity:0,
                        //             price:item.costPrice,
                        //             productId:item.productId,
                        //             productName:item.productName,
                        //             productNumber:item.productNumber,
                        //             sku:item.sku,
                        //             skuNumber:item.number,
                        //             itemValueLineId:item.id,
                        //             remarks:'',
                        //         });
                        //     }
                        // }

                    })
                    getQuantity();
                    // var e = ListService.inList2(res[0],vm.data.itemLines ,["id"],["itemValueLineId"]);
                    // if(e){
                    //     MessageService.error(res[0].sku + "已存在");
                    // }else{
                    //     vm.data.itemLines.push({
                    //         unit:res[0].unit,
                    //         quantity:0,
                    //         requireQuantity:0,
                    //         price:res[0].costPrice,
                    //         productId:res[0].productId,
                    //         productName:res[0].productName,
                    //         productNumber:res[0].productNumber,
                    //         sku:res[0].sku,
                    //         itemValueLineId:res[0].id,
                    //         remarks:'',
                    //     });
                    //     getQuantity()
                    // }
                }
            });
        }

        $scope.getStatus = function(item){
            switch (item) {
                case 'UnShippedOut':
                    return '未出库';
                case 'ShippedOut':
                    return '已出库';
                case 'ShippedIn':
                    return '已入库';
                case 'Close':
                    return '关闭';
                default:
                    return item
            }
        }
    }
})();
