(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('StoreRefundGoodsDetailsController', StoreRefundGoodsDetailsController);

    StoreRefundGoodsDetailsController.$inject = ['$scope', 'Principal', '$state', 'MessageService', '$localStorage', '$stateParams', '$rootScope', '$uibModal','refundGoods','FindWarehouse'];

    function StoreRefundGoodsDetailsController ($scope, Principal,  $state, MessageService, $localStorage, $stateParams, $rootScope, $uibModal,refundGoods,FindWarehouse) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.searchWarehouse= searchWarehouse

        init()

        function init(){
            refundGoods.get({id : $stateParams.id},function(res){
                vm.data = res
                vm.data.lines.forEach(function(item,index){
                    if(vm.data.status === 'ToConfirm'){
                        item.warehouseId = item.warehouses[0].id
                        item.refundQuantity = item.refundQuantity !== null ? item.refundQuantity : item.quantity
                        item.refundPrice = item.refundPrice !== null ? item.refundPrice : item.price
                    }
                    searchWarehouse(item)
                })
            },function(res){
                MessageService.error("查询失败，请重试！");
            })
        }

        //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    stockOrderNumber: $stateParams.stockOrderNumber,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    number: $stateParams.number,
                    storeName: $stateParams.storeName,
                });
            }else {
                history.back();
            }
        };

        // 是否确认退货
        $scope.save = function(item){
            if(!valiData(vm.data.lines)) return
            MessageService.confirm("是否确认退货?", function () {
                refundGoods.confirm(vm.data, function () {
                    init();
                    MessageService.success("退货成功");
                }, function (error) {
                    MessageService.error(error.data.message || "退货失败，请重试！");
                });
            });
        }

        function valiData(data){
            var flag = true
            data.forEach(function(item){
                if(flag){
                    if(!item.warehouseId){
                        MessageService.error("请选择退货入库仓库！");
                        flag = false
                        return
                    }
                    if(item.refundQuantity == null){
                        MessageService.error("请输入实收数量！");
                        flag = false
                        return
                    }
                    if(!item.refundPrice){
                        MessageService.error("请输入实收单价！");
                        flag = false
                        return
                    }
                }
            })
            return flag
        }

        // 是否确认关闭
        $scope.close = function(item){
            MessageService.confirm("是否确认关闭?", function () {
                refundGoods.close({id:vm.data.id}, function () {
                    init();
                    MessageService.success("关闭成功");
                }, function (error) {
                    MessageService.error("关闭失败，请重试！");
                });
            });
        }

        $scope.getStatus = function(item){
            switch (item) {
                case 'ToConfirm':
                    return '待确认';
                case 'Confirm':
                    return '已确认';
                case 'Close':
                    return '已关闭';
                default:
                    return item
            }
        }

        // 查库存
        function searchWarehouse(item,index){
            // 仓库名赋值
            item.warehouses.forEach(function(itm){
                if(itm.id === item.warehouseId) item.warehouseName = itm.name
            })
            var params = {
                warehouseId:item.warehouseId,
                productNumber:item.scmNumber,
            }
            if(!item.warehouseId) {
                item.inventoryDesc = ''
                return
            }
            FindWarehouse.searchByidAndNumber(params, function (res) {
                if(res.message && res.message.usableQuantity !== null){
                    item.inventoryDesc = res.message.usableQuantity
                }else{
                    item.inventoryDesc = ''
                }
            }, function (error) {
                MessageService.error("查询失败，请重试！");
            });
        };
    }
})();
