(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OutBoundDifferenceDetailsController', OutBoundDifferenceDetailsController);

    OutBoundDifferenceDetailsController.$inject = ['$scope', 'Principal', '$state', 'MessageService', '$localStorage', '$stateParams', '$rootScope', '$uibModal','stockDiffs','FindWarehouse'];

    function OutBoundDifferenceDetailsController ($scope, Principal,  $state, MessageService, $localStorage, $stateParams, $rootScope, $uibModal,stockDiffs,FindWarehouse) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;


        init()

        function init(){
            stockDiffs.get({id : $stateParams.id},function(res){
                vm.data = res
                // 默认选中当前商品的第一个仓库并查询库存
                if(vm.data.status === 'UnShippedOut'){
                    vm.data.lines.forEach(function(item,index){
                        item.outNum = item.num
                        item.warehouseId = item.warehouses[0].id
                        // searchWarehouse(item)
                    })
                }

            },function(res){
                MessageService.error("查询失败，请重试！");
            })
        }

        //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    stockOrderNumber: $stateParams.stockOrderNumber,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    number: $stateParams.number,
                    storeName: $stateParams.storeName,
                });
            }else {
                history.back();
            }
        };

        // 处理差异
        $scope.handleDifference = function(item){
            MessageService.confirm("是否确认处理差异?", function () {
                stockDiffs.confirm({id: vm.data.id,remark:vm.data.remark}, function () {
                    init();
                    MessageService.success("确认成功");
                }, function (error) {
                    MessageService.error("确认失败，请重试！");
                });
            });
        }

        $scope.getStatus = function(item){
            switch (item) {
                case 'ToCheck':
                    return '待确认';
                case 'Check':
                    return '已确认';
                default:
                    return item
            }
        }
    }
})();
