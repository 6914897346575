(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OutsourcingTaskController', OutsourcingTaskController);

    OutsourcingTaskController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','$uibModal','MessageService', 'ParseLinks', 'Supplier','SalesOrder', 'Product', '$localStorage','$http', '$location'];

    function OutsourcingTaskController ($scope, Principal, LoginService, $state,User,$uibModal,MessageService, ParseLinks, Supplier,SalesOrder, Product, $localStorage, $http, $location) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_ENTRUST').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });

        vm.token = $localStorage.authenticationToken;

        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tablePitch = '';
        vm.stateList = [
            {name:'待分配', value:'ToBeAssigned'},
            {name:'已分配', value: 'Assigned'},
            {name:'生产中', value: 'Producing'},
            {name:'被冻结', value: 'Freeze'},
            {name:'待发货', value: 'ToBeDelviery'},
            {name:'外发失败', value: 'OutFailed'}
        ];

        vm.makeups= [
            {name: '未拼版', id: 'NoMakeup'},
            {name: '拼版中', id: 'InMakeup'},
            {name: '已拼版', id: 'Makeuped'}
        ];
        $scope.selectSupplier =function(data){
            vm.tableData.makeupId = "";
            if(data){
                vm.tableData.supplierId = data.id;
            }else {
                vm.tableData.supplierId = "";
            }
        };
        vm.backList = [
            {name:'已回件', value: "true"},
            {name:'未回件', value: "false"}
        ];

        vm.conditionList = [
            {name:'全部', value:''},
            {name:'待分配', value:'ToBeAssigned'},
            {name:'已分配', value: 'Assigned'},
            {name:'生产中', value: 'Producing'},
            {name:'外发失败', value: 'OutFailed'},
        ];
        vm.isFilter = false;

        vm.predicate = 'createdDate';
        vm.resolve = false;

        vm.tableData = {
            sort: [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')],
            page: Number($location.search().page) || 0,
            size: 20,
            taskList:true,//生产中心默认字段  区别于订单中心的下载
            state: $location.search().state || '',
            startDate: $location.search().startDate || '',
            endDate: $location.search().endDate || '',
            supplierId: $location.search().supplierId || '',
            makeupId: Number($location.search().makeupId) || '',
            back: $location.search().back || '',
            productId: $location.search().productId || '',
            orderType:"Ordering",
            keyword: $location.search().keyword || ''
        };
        angular.element("#startDate").val($location.search().startDate || GetDateStr(-31));
        angular.element("#endDate").val($location.search().endDate || GetDateStr(0));
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        vm.isFilter = vm.tableData.supplierId || vm.tableData.makeupId || vm.tableData.back ? true : false;
        Product.getAllByCondition({items:[{key:"category",op:"=",value:'GeneralProduct'}]}, function (res) {
            vm.productList = res.filter(function(item){return item.enable;});
            if(vm.tableData.productId != ''){
                for(var i=0; i<vm.productList.length; i++ ){
                    if(vm.productList[i].id == vm.tableData.productId){
                        vm.product = vm.productList[i];
                    }
                }
            }
        });
        Supplier.getAll("", function (res) {
            vm.suppliers = res.filter(function(item){return item.enable;});
            if(vm.tableData.supplierId != ''){
                for(var i=0; i<vm.suppliers.length; i++ ){
                    if(vm.suppliers[i].id == vm.tableData.supplierId){
                        vm.supplier = vm.suppliers[i];
                        vm.makeups = vm.suppliers[i].makeups;
                        // setTimeout(function () {
                        //     vm.tableData.makeupId = Number($location.search().makeupId) || '';
                        // },200)
                    }
                }
            }
        });

        $scope.selectAll = function () {
            if($scope.select_all) {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = true;
                })
                $scope.checked = vm.salesOrders.map(function(value){
                    return value.id;
                })
            }else {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = false;
                    $scope.checked = [];
                })
            }
        };
        // $scope.selectSupplier =function(data){
        //     if(data){
        //         vm.tableData.supplierId = data.id;
        //     }else {
        //         vm.tableData.supplierId = "";
        //     }
        // };
        $scope.selectProduct =function(data){
            if(data){
                vm.tableData.productId = data.id;
            }else {
                vm.tableData.productId = "";
            }
        };
        $scope.selectOne = function () {
            angular.forEach(vm.salesOrders , function (item) {
                var index = $scope.checked.indexOf(item.id);
                if(item.checked && index === -1) {
                    $scope.checked.push(item.id);
                } else if (!item.checked && index !== -1){
                    $scope.checked.splice(index, 1);
                };
            });

            if (vm.salesOrders.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }
        };


        // $scope.tableTr = function(data){
        //     vm.tablePitch = data;
        // };

        $scope.sort = function () {
            vm.tableData.sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            init();
        }

        init();
        function init() {
            $scope.checked = [];
            $scope.select_all = false;
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());

            $location.search({
                'page':vm.tableData.page,
                'state':vm.tableData.state,
                'startDate':vm.tableData.startDate,
                'endDate':vm.tableData.endDate,
                'supplierId': vm.tableData.supplierId,
                'makeupId': vm.tableData.makeupId,
                'back': vm.tableData.back,
                'productId': vm.tableData.productId,
                'keyword': vm.tableData.keyword
            });
            SalesOrder.query(vm.tableData, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.salesOrders = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.conditionSelect = function(data){
            vm.tableData.state = data.value;
            vm.tableData.page = 0;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.reset = function(){
            vm.tableData = {
                sort: ['createdDate,DESC'],
                taskList:true,
                page: 0,
                size: 20,
                state:'',
                startDate: '',
                endDate: '',
                supplierId: '',
                makeupId: '',
                back: '',
                productId: ''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            vm.supplier = "";
            vm.product = "";
            init();
        };
        //查看订单
        //$scope.orderLook = function () {
        //    $state.go('orderDetails',{id:vm.tablePitch.id});
        //};


        // 分配
        $scope.distributeTask = function(){
            if($scope.checked.length >0) {
                var _arr = [];
                for(var i=0; i<vm.salesOrders.length; i++){
                    for(var j=0; j<$scope.checked.length; j++){
                        if($scope.checked[j] == vm.salesOrders[i].id){
                            _arr.push(vm.salesOrders[i])
                        }
                    }
                }
                for(var k=0; k<_arr.length; k++){
                    if(_arr[k].status != "ToBeAssigned"){
                        MessageService.error("该订单不能再分配");
                        return
                    }
                    if(_arr[0].productId != _arr[k].productId){
                        MessageService.error("请选择相同的商品");
                        return
                    }
                }
                $uibModal.open({
                    templateUrl: 'app/outsourcing/distribute.html',
                    controller: 'DistributeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('supplier');
                            return $translate.refresh();
                        }],
                        msgdata: function () {
                            var _obg = {
                                outsources: $scope.checked,
                                productId: _arr[0].productId,
                                propertyId: _arr[0].propertyId
                            };
                            return _obg;
                        },
                        entity: function () {
                            return null;
                        }
                    }
                }).result.then(function (result) {
                    init();
                    $state.go('outsourcingTask');
                });
            }else{
                MessageService.error("请选择未分配的订单");
            }
        };

        //   撤销
        $scope.revoke = function(){
            //获取未分配订单个数
            if($scope.checked.length >0) {
                var _arr = [];
                for(var i=0; i<vm.salesOrders.length; i++){
                    for(var j=0; j<$scope.checked.length; j++){
                        if($scope.checked[j] == vm.salesOrders[i].id){
                            _arr.push(vm.salesOrders[i])
                        }
                    }
                }
                for(var k=0; k<_arr.length; k++){
                    if(_arr[k].makeupStatus == 'InMakeup' || _arr[k].status == "Freeze" ){
                        MessageService.error("该订单不能撤销");
                        return
                    }

                    if(_arr[k].status != "Assigned" && _arr[k].makeupStatus != 'NoMakeup' && _arr[k].makeupStatus != 'NotRequiredMakeup'){
                        MessageService.error("该订单不能撤销");
                        return
                    }
                }
                MessageService.confirm("确认要撤销该订单？", function () {
                    SalesOrder.noAllot({ids: $scope.checked.join(',')}, function () {
                        MessageService.success("撤销成功");
                        init();
                    }, function (error) {
                        MessageService.error("撤销失败，请重试！");
                    });
                });
            }else{
                MessageService.error("请选择已分配的订单撤销");
            }
        }

        vm.makeupStatus = makeupStatus;
        function makeupStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "NoMakeup":
                    return "未拼版";
                case "Makeuped":
                    return "已拼版";
                case "InMakeup":
                    return "拼版中";
                case "NotRequiredMakeup":
                    return "不拼版";
                default:
                    return "没有对应的状态";
            }
        }

        $scope.getPrePressStatus = function(target) {
            if (target == null || target == "") {
                return;
            }
            switch (target) {
                case "Makeuped":
                    return "印前处理完成";
                case "InMakeup":
                    return "印前处理中";
                default:
                    return "没有对应的状态";
            }
        }

        //下载
        $scope.orderExport = function () {
            $http({
                url: 'api/sales_order/exportData1',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (error) {
            });
        };
    }
})();
