(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderGeneralController', OrderGeneralController);

    OrderGeneralController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder', '$uibModal', 'Supplier','Product',
        '$localStorage','$http', '$stateParams', '$rootScope', '$location', 'ProductCategory','OrderPack','DeliveryRequest','DeliverySettings'];

    function OrderGeneralController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, SalesOrder, $uibModal, Supplier, Product,
                                     $localStorage, $http, $stateParams, $rootScope, $location, ProductCategory,OrderPack, DeliveryRequest,DeliverySettings) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        var warehouse = {}
        vm.token = $localStorage.authenticationToken;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tablePitch = '';
        vm.sourceList = [
            {name:'自营订单', value:'self'},
            {name:'第三方平台', value: '第三方平台'},
            {name:'外接订单', value: '外接订单'}
        ];
        vm.backList = [
            {name:'已回件', value: "true"},
            {name:'未回件', value: "false"}
        ];
        vm.replenishmentList = [
            {name:'是', value: "true"},
            {name:'否', value: "false"}
        ];
        vm.refundList = [
            {name:'是', value: "true"},
            {name:'否', value: "false"}
        ];
        vm.conditionList = [
            {name:'全部', value:''},
            {name:'待发货', value:'ToDelivery'},
            {name:'已发货', value: 'Delivered'},
            {name:'已收货', value: 'Received'},
            {name:'已关闭', value: 'Close'}
        ];
        //商品品类 一级
        vm.promoteProducts = ProductCategory.getAllCategory();
        vm.productCategoryId = "";
        vm.productCategorySecondId = "";

        vm.predicate = 'createdDate';
        vm.resolve = false;
        vm.page = $stateParams.page || $location.search().page;
        vm.tableData = {
            page: Number(vm.page) || 0,
            size: 20,
            state:$stateParams.state || $location.search().state || '',
            source:$stateParams.source || $location.search().source || '',
            startDate: $stateParams.startDate || $location.search().startDate || GetDateStr(-31),
            endDate: $stateParams.endDate || $location.search().endDate || GetDateStr(0),
            number:$stateParams.number || $location.search().number || '',
            logisticsNumber:$stateParams.logisticsNumber || $location.search().logisticsNumber || '',
            orderType: 'General'
        };
        angular.element("#startDate").val($stateParams.startDate || $location.search().startDate || GetDateStr(-31));
        angular.element("#endDate").val($stateParams.endDate || $location.search().endDate || GetDateStr(0));
        vm.isFilter = vm.tableData.back || vm.tableData.replenishment || vm.tableData.refund ? true : false;
        Supplier.getAll("", function (res) {
            vm.suppliers = res;
            if(vm.tableData.supplierId != ''){
                for(var i=0; i<vm.suppliers.length; i++ ){
                    if(vm.suppliers[i].id == vm.tableData.supplierId){
                        vm.supplier = vm.suppliers[i];
                    }
                }
            }
        });
        Product.getAllByCategory({type: 'OrderingProduct'}, function (res) {
            vm.productList = res;
            if(vm.tableData.productId != ''){
                for(var i=0; i<vm.productList.length; i++ ){
                    if(vm.productList[i].id == vm.tableData.productId){
                        vm.product = vm.productList[i];
                        vm.skuList = vm.productList[i].propertyConfigs;
                    }
                }
            }
        });

        // Product.getAllByCategory({type: 'Parts'}, function(res){
        //     angular.forEach(res, function(item){
        //         if(vm.promoteProducts.indexOf(item.name) == -1){
        //             vm.promoteProducts.push(item.name);
        //         }
        //     })
        // });

        //获取选中的数组
        $scope.checked =[];
        $scope.tableTr = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.salesOrders.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }

            vm.tablePitch = $scope.checked.length >0 ?true:false;
        };

        $scope.sort = function () {
            vm.tableData.sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            init();
        }

        init();
        function init() {
            vm.tablePitch = '';
            $scope.select_all = false;
            $scope.checked =[];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            $location.search({
                'page':vm.tableData.page,
                'state':vm.tableData.state,
                // 'source':vm.tableData.source,
                'startDate':vm.tableData.startDate,
                'endDate':vm.tableData.endDate,
                'number':vm.tableData.number,
                'logisticsNumber':vm.tableData.logisticsNumber,
                'orderType':'General',

            });
            OrderPack.query(vm.tableData, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                res.forEach(function(item){
                   item.productStr = ''
                    item.salesOrders.forEach(function(itm,idx){
                        if(idx!==item.salesOrders.length-1){
                            item.productStr = item.productStr + itm.productName + ','
                        }else{
                            item.productStr = item.productStr + itm.productName
                        }
                    })
                })
                vm.salesOrders = res
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });

            //获取订单总数和总张数
            SalesOrder.getNumByCondition(vm.tableData,function(res) {
                $scope.orderNumberTotal = res.total;
                $scope.pageCountTotal = res.pages;
            })

        };
        //商品1级品类
        $scope.promoteProductsSelect = function(){
            vm.promoteProductsSecond = [];
            vm.tableData.productCategoryId = vm.productCategoryId;
            for(var i=0; i<vm.promoteProducts.length; i++){
                if(vm.promoteProducts[i].id == vm.productCategoryId){
                    // console.log(vm.promoteProducts[i]);
                    vm.promoteProductsSecond = vm.promoteProducts[i].productCategoryVMS;
                }
            }
        }
        //商品品类 二级
        $scope.promoteProductsSecondSelect = function(){
            if(!vm.productCategorySecondId){
                vm.tableData.productCategoryId = vm.productCategoryId;
            }else {
                vm.tableData.productCategoryId = vm.productCategorySecondId;
            }
        }

        $scope.selectSupplier =function(data){
            if(data){
                vm.tableData.supplierId = data.id;
            }else {
                vm.tableData.supplierId = "";
            }
        };
        $scope.selectProduct =function(data){
            if(data){
                vm.tableData.productId = data.id;
                vm.skuList = data.propertyConfigs;
            }else {
                vm.tableData.productId = "";
                vm.tableData.sku = "";
                vm.skuList = [];
            }
        };
        $scope.filter = function(){
            vm.isFilter = !vm.isFilter;
            //vm.tableData.back = '';
            //vm.tableData.replenishment= '';
            //vm.tableData.refund= '';
        };
        $scope.conditionSelect = function(data){
            vm.tableData.state = data.value;
            vm.tableData.page = 0;
            init()
        };
        $scope.warningSelect = function(){
            vm.tableData.state = "";
            vm.tableData.page = 0;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        // $scope.tableTr = function(data){
        //     vm.tablePitch = data;
        //     for(var i=0; i<vm.salesOrders.length; i++){
        //         if(data.id == vm.salesOrders[i].id){
        //             vm.salesOrders[i].pitchOn = true;
        //         }else {
        //             vm.salesOrders[i].pitchOn = false;
        //         }
        //     }
        // };
        $scope.reset = function(){
            vm.isFilter = false;
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                state:'',
                source:'',
                startDate: '',
                endDate: '',
                back: '',
                timeWarning:'',
                replenishment: '',
                refund: '',
                condition: '全部',
                supplierId: '',
                productId: '',
                productCategoryId:'',
                number:'',
                logisticsNumber:'',
                sku:'',
                orderType:'General',
            };
            vm.supplier = "";
            vm.product = "";
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
            localStorage.removeItem('tableData');
        };
        //发货
        $scope.delivery = function (record) {
            checkWarehouse(record)
        };

        //客户备注
        $scope.orderRemark = function () {
            // if(!vm.tablePitch){
            //     MessageService.error("请选择订单");
            //     return
            // }
            if($scope.checked.length >1){
                MessageService.error('只能选择一个订单 ！');
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/order/order-remark.html',
                    controller: 'OrderRemarkController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return
                        }]
                    }
                }).result.then(function (backData) {
                    // console.log(backData);
                });
            }
        };
        //补货订单
        $scope.orderReplenishment = function(){
            // if(!vm.tablePitch){
            //     MessageService.error("请选择订单");
            //     return
            // }
            if($scope.checked.length >1){
                MessageService.error('只能选择一个订单 ！');
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/order/order-replenishment.html',
                    controller: 'OrderReplenishmentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return $scope.checked[0]
                        }]
                    }
                }).result.then(function (backData) {
                    if (backData) {
                        init()
                    }
                });
            }
        };

        //选择物流公司
        $scope.orderExpress = function(){
            //如果是已发货状态则不能修改物流公司
            var flagExpress =false;
            angular.forEach($scope.checked,function(item){
                if(item.status =="Delivered"){
                    flagExpress =true;
                    return;
                }
            });
            if(flagExpress){
                MessageService.error('存在已发货的订单，不能修改物流公司，请重新选择！');
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/order/order-express.html',
                    controller: 'OrderExpressController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return $scope.checked
                        }]
                    }
                }).result.then(function (backData) {
                    if (backData) {
                        init()
                    }
                });
            }
        };
        //修改
        $scope.modification = function(){
            var flagExpress =false;
            var isStatus =false;
            var isStatusProducing =false;
            angular.forEach($scope.checked,function(item, index){
                if(item.status !="Assigned" && item.status !="Producing" && item.status !="Delivered"){
                    flagExpress =true;
                    return;
                }
                if(index>0 && item.status != $scope.checked[index-1].status){
                    isStatus =true;
                    return;
                }
                if(item.status == "Producing" && $scope.checked.length>1){
                    isStatusProducing =true;
                    return;
                }
            });
            if(flagExpress){
                MessageService.error('只能修改已分配、生产中、已发货这三个状态的订单');
                return;
            }
            if(isStatus){
                MessageService.error('请选择相同状态下的订单');
                return;
            }
            if(isStatusProducing){
                MessageService.error('生产中的订单只能选择一个');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/order/order-modification.html',
                controller: 'OrderModificationController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return $scope.checked
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        }
        //导出
        $scope.orderExport = function () {
            $http({
                url: 'api/order_pack/export',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            $rootScope.previousState = from;
            $rootScope.previousParams = fromParams;
        });
        //日期
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }


        // 自动设置符合订购数量要求的仓库
        function checkWarehouse(record){
            OrderPack.fetchReserve({id : record.id},function(res){
                record.salesOrders.forEach(function(item){
                    res.lines.forEach(function(itm){
                        if(item.id === itm.id){
                            // 按仓库数量倒序排列
                            itm.reserves.sort(function(a,b){
                                return b.quantity - a.quantity
                            })

                            warehouse =  itm.reserves.find(function(_itm){
                                return _itm.quantity >= item.quantity
                            }) || {}
                            item.warehouseId =  warehouse && warehouse.warehouseId || ''
                            item.warehouseQuantity =  warehouse && warehouse.quantity || 0
                            warehouse = {}
                        }
                    })
                })

                var warningArr  = []
                record.salesOrders.forEach(function(item){
                    if(item.quantity > item.warehouseQuantity){
                    warningArr.push(item.productName)
                    }
                })
                var _warningArr = warningArr.join(',')
                if(warningArr.length > 0) {
                    MessageService.error(_warningArr + '库存不足，无法发货')
                    return
                }
                // 打开发货弹框
                openDeliveryDialog(record)
            },function(res){
                console.log(res);
            })
        }

        function openDeliveryDialog(record) {
            $uibModal.open({
                templateUrl: 'app/order/delivery-dialog.html',
                controller: 'DeliveryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return record;
                    },
                    isDetail:function(){
                        return false
                    }
                }
            }).result.then(function(result){
                if(result){
                    init();
                }
            });
        };

        $scope.getStatus = function(item){
            switch (item) {
                // case 'UnPaid':
                //     return '未支付';
                // case 'Producing':
                //     return '生产中';
                case 'ToDelivery':
                    return '待发货';
                case 'Delivered':
                    return '已发货';
                case 'Received':
                    return '已收货';
                case 'Completed':
                    return '已完成';
                case 'Close':
                    return '已关闭';
                default:
                    return item
            }
        }
    }
})();
