(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PriceListCopyController', PriceListCopyController);

    PriceListCopyController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'MessageService', 'Channels', 'Supplier', 'CustomerHub','Prices'];

    function PriceListCopyController ($scope, msgdata, $uibModalInstance, MessageService, Channels, Supplier, CustomerHub,Prices) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        init();

        vm.priceTypes = [
            {name: '供应商价目表', value: 'supplier'},
            // {name: '客户价目表', value: 'customer'},
            {name: '渠道价目表', value: 'channel'}
        ];

        $scope.choosePriceType = function () {
            vm.data.supplierId = null;
            vm.data.customerHubId = null;
            vm.data.channelId = null;
        }

        function init() {
            vm.data = angular.copy(msgdata);
            console.log(vm.data)
            vm.data.id = null;
            angular.forEach(vm.data.priceLines,function (item) {
                item.priceId=null;
                item.id=null;
                angular.forEach(item.priceRules,function (rule) {
                    rule.priceLineId=null;
                    rule.id=null;
                })
            })
            //获取渠道
            Channels.getAll(function (res) {
                vm.channels = res.filter(function (item) {
                    return item.enable;
                });
            });

            //供应商
            Supplier.getAll("", function (res) {
                vm.suppliers = res.filter(function (item) {
                    return item.enable;
                });
            });

            //客户
            CustomerHub.getAll("", function (res) {
                vm.customerHubs = res.filter(function (item) {
                    return item.enable;
                });
            });
        }

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            vm.isSaving = true;
            console.log(vm.data)
            // return
            Prices.create(vm.data, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $uibModalInstance.close(vm.data);
            }

            function onSaveError() {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        }
    }
})();
