(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProductItemsController', ProductItemsController);

    ProductItemsController.$inject = ['$scope', 'Items', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal','$uibModalInstance', 'entity'];

    function ProductItemsController ($scope, Items, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal, $uibModalInstance, entity) {
        var vm = this;
        vm.items = entity;
        console.log(vm.items)
        vm.itemsList = Items.getAll();
        $scope.itemSelect = function(data, index){
            if(data.id){
                if(!itemIndex(data.id,index)){
                    MessageService.error("已存在");
                    vm.items[index] = null;
                    return
                }
                for(var i=0; i<vm.itemsList.length; i++){
                    if(data.id == vm.itemsList[i].id){
                        vm.items[index] = vm.itemsList[i];
                        break
                    }
                }
            }else{
                vm.items[index] = null;
            }
            console.log(vm.items)
        }
        function itemIndex(id,index){
            for(var i=0; i<vm.items.length; i++){
                if(vm.items[i] && id == vm.items[i].id && i!=index){
                    return false
                }
            }
            return true
        }
        //属性删除
        $scope.propertyDelete = function(index){
            vm.items.splice(index,1);
        };
        //属性新增
        $scope.propertyAdd = function(){
            vm.items.push({id: null});
        };
        //生成
        $scope.save = function () {
            if(vm.items.length == 0){
                MessageService.error("请添加属性");
                return
            }
            for(var i=0;i<vm.items.length;i++){
                if(!vm.items[i] || !vm.items[i].id){
                    MessageService.error("请添加属性");
                    return false
                }
            }
            $uibModalInstance.close(vm.items);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
