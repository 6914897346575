(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PropertyController', PropertyController);

    PropertyController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal','$uibModalInstance', 'entity'];

    function PropertyController ($scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal, $uibModalInstance, entity) {
        var vm = this;
        vm.property = entity;
        console.log(vm.property)
        //生成
        $scope.save = function () {
            var _arr = [];
            for(var i=0; i<vm.property.length; i++){
                var _arr1 = [];
                for(var j=0; j<vm.property[i].itemValues.length; j++){
                    if(vm.property[i].itemValues[j].checked){
                        var _obj = vm.property[i].itemValues[j].name;
                        _arr1.push({
                            name: vm.property[i].itemValues[j].name,
                            number: vm.property[i].id + '_' + vm.property[i].itemValues[j].id
                        })
                    }
                }
                if(_arr1.length == 0){
                    MessageService.error("每个属性最少选择一项");
                    return
                }
                _arr.push(_arr1)
            }

            $uibModalInstance.close(_arr);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close();
        }
    }
})();
