(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchasingApplicationController', PurchaseController);

    PurchaseController.$inject = ['$scope', 'Principal', '$state', 'MessageService', 'PurchaseOrder', 'ParseLinks', '$uibModal', '$timeout', '$stateParams','PurchaseProductLine','Depository'];

    function PurchaseController ($scope, Principal, $state, MessageService, PurchaseOrder, ParseLinks, $uibModal, $timeout, $stateParams,PurchaseProductLine,Depository) {
        var vm = this;

        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_PRODUCT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问的权限！");
                $state.go("home");
            }
        });

        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.selection = [
            {name:'全部', value:'all'},
            {name:'待审核', value:'ToBeAudited'},
            {name:'待修订', value:'ToBeRevised'},
            {name:'已下达', value:'AlreadyIssued'},
            {name:'采购预警', value:'Warning'}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            keyword: '',
            type:'all',
            pageType: 'demand'
        };

        Depository.getAll({},function(res){
            vm.categories = res.filter(function(item){return item.defaulted});
        });
        //获取选中的数组
        $scope.checked =[];
        $scope.selectOne1 = function (e,data){
            data.checked = !data.checked;
            fn(e)
            $scope.selectOne(e,data);
        }
        $scope.selectOne = function(e,data){
            fn(e)
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.tableList.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }
        };

        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            // if (e && e.preventDefault) {
            //     e.preventDefault();
            // } else {
            //     // IE中阻止函数器默认动作的方式
            //     window.event.returnValue = false;
            // }
        }
        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.tableList.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
            }
        };

        $scope.cancelModal = function() {
            vm.messageStr = '';
            $('#submitModal').modal('hide');
        };

        $scope.confirmSubmit = function() {
            var ids = '';
            for (var i = $scope.checked.length - 1; i >= 0; i--) {
                ids += $scope.checked[i].id + ",";
            }
            ids = ids.substr(0, ids.length - 1);
            if (vm.messageType == 1) {
                // 审核
                PurchaseProductLine.examineOrder({ids: ids}, function(){
                    MessageService.success('审核成功');
                    $scope.cancelModal();
                    init();
                }, function(){
                    MessageService.error('审核失败');
                });
            }
        };

        // 转采购单
        $scope.submission = function() {
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }

            var ids = '';
            var status = ''
            var warehouseId = ''
            var supplierId = ''
            for (var i = $scope.checked.length - 1; i >= 0; i--) {
                if ($scope.checked[i].state != 'Examined') {
                    MessageService.error('存在未审核需求');
                    return;
                }
                if (!$scope.checked[i].status) {
                    MessageService.error('采购类型不能为空');
                    return;
                }
                ids += $scope.checked[i].id + ",";
                if(status && status!=$scope.checked[i].status){
                    MessageService.error('请选择同一采购类型的商品转采购单');
                    return;
                }else{
                    status = $scope.checked[i].status;
                }
                if(warehouseId && warehouseId!=$scope.checked[i].warehouseId){
                    MessageService.error('请选择同一入库仓库的商品转采购单');
                    return;
                }else{
                    warehouseId = $scope.checked[i].warehouseId;
                }
                if(supplierId && supplierId!=$scope.checked[i].supplierId){
                    MessageService.error('请选择同一供应商的商品转采购单');
                    return;
                }else{
                    supplierId = $scope.checked[i].supplierId;
                }
            }
            ids = ids.substr(0, ids.length - 1);
            PurchaseOrder.createOrderByDemand
            $state.go("purchaseProductLineEdit", {id: ids});
        };

        // 审核需求订单
        $scope.toExamine = function() {

            if($scope.checked.length == 0){
                MessageService.error('请选择列表项');
                return;
            }

            vm.messageStr = '审核操作确认。';
            vm.messageType = 1;     // 审核
            $('#submitModal').modal('show');
        }

        // 取消采购需求
        $scope.cancelledBtn = function() {
            if($scope.checked.length == 0){
                MessageService.error('请选择列表项');
                return;
            }

            var ids = '';
            for (var i = $scope.checked.length - 1; i >= 0; i--) {
                ids += $scope.checked[i].id + ",";
            }
            ids = ids.substr(0, ids.length - 1);

            PurchaseProductLine.cancelDemand({ids: ids}, function(){
                MessageService.success('取消成功');
                init();
            }, function(){
                MessageService.error('取消失败');
            });
        }

        init();
        function init() {
            vm.tableList = [];
            $scope.checked = [];
            $scope.select_all = false;
            PurchaseProductLine.demandPage(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.stateInfo = function (item){
            switch (item) {
                case 'New':
                    return '待审核';
                case 'Examined':
                    return '审核通过';
                default:
                    return item
            }
        }
    }

})();
