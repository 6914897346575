(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ProductAddDialogController', ProductAddDialogController);

    ProductAddDialogController.$inject = ['$q', '$scope', 'productType', 'MessageService', '$sessionStorage', 'entity', '$uibModalInstance', 'Product'];

    function ProductAddDialogController ($q, $scope, productType, MessageService, $sessionStorage, entity, $uibModalInstance, Product) {
        var vm = this;
        // vm.data = entity;
        vm.products =
        vm.data = []
        // console.log('已选商品',entity);
        // console.log('productType',productType);
        // console.log('products',vm.products);

        $q.all([vm.products.$promise]).then()
        init()
        function init(){
        Product.getAllByCondition({items:[{key:"category",op:"=",value:productType}]},function(res){
                res.forEach(function(item){
                    entity.forEach(function(itm){
                        item.propertyConfigs.forEach(function(property,index){
                            if(property.id == itm){
                                item.propertyConfigs.splice(index,1)
                            }
                            })
                })

            })
            vm.products = res
        })
        }

        // 添加商品
        $scope.productAdd = function (index) {
            vm.data.push({})
        }

        // 删除商品
        $scope.productDelete = function (index) {
            vm.data.splice(index,1)
        }

        //商品选择框change事件
        $scope.selectPurchaseProduct = function(data){
            data.propertyConfigs=[];
            data.propertyConfigId=null;
            if(data.productId){
                for(var i=0; i<vm.products.length; i++){
                    if(data.productId == vm.products[i].id){
                        data.propertyConfigs=vm.products[i].propertyConfigs;
                        data.measurementName=vm.products[i].measurementName;
                        data.measurementId=vm.products[i].measurementId;
                        data.productName=vm.products[i].name;
                        data.productNumber = vm.products[i].number
                        break
                    }
                }
            }
        };

        //sku选择框change事件
        $scope.selectPurchaseProductSku = function(data,index,item){
            var selectedSku =  vm.data.findIndex(function(item){
                return item.propertyConfigId === data.propertyConfigId
            })
            if(selectedSku > -1 && index !== selectedSku){
                MessageService.error("该sku已存在 请重新选择 ");
                data.propertyConfigId = ''
                return
            }

            if(data.propertyConfigId){
                for(var i=0; i<data.propertyConfigs.length; i++){
                    if(data.propertyConfigId == data.propertyConfigs[i].id){
                        data.sku = data.propertyConfigs[i].sku
                        data.skuNumber = data.propertyConfigs[i].skuNumber
                        data.unit = data.measurementName
                        data.beforeQuantity = 0
                        data.cancelled = false
                        data.operator = $sessionStorage.user.fullname
                        data.productType = productType
                        data.remarks = ''
                        break
                    }
                }
            }
        };

        // 保存
        $scope.save = function () {
            if(validData(vm.data)){
                $uibModalInstance.close(vm.data);
            }
        }

       function  validData(data){
            var flag = true
            data.forEach(function(item){
                if(flag){
                    if(!item.productId || item.productId == null || item.productId == '') {
                        flag = false
                        MessageService.error('请选择商品!');
                        return
                    }
                    if(item.propertyConfigId == null || item.propertyConfigId == '') {
                        flag = false
                        MessageService.error('有商品未选择sku，无法确定!');
                        return
                    }
                    if(item.price == null || item.price == ''|| item.price == undefined) {
                        flag = false
                        MessageService.error('有商品未设置单价，无法确定!');
                        return
                    }
                }
            })
            return flag
       }
        //取消
        $scope.cancelRecord = function () {
            $uibModalInstance.close();
        }
    }
})();
