(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MakeUpController', MakeUpController);

    MakeUpController.$inject = ['$scope', 'Principal', 'Makeup', '$state', 'OrderMakeupFile','$uibModal','MessageService', 'ParseLinks', 'Supplier','SalesOrder',   '$localStorage','$http', '$location'];

    function MakeUpController ($scope, Principal, Makeup, $state, OrderMakeupFile,$uibModal,MessageService, ParseLinks, Supplier,SalesOrder,  $localStorage, $http, $location) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_ENTRUST').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });

        vm.token = $localStorage.authenticationToken;

        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tablePitch = '';
        
        vm.makeupStatusMap = [
            {name: '未拼版', id: 'NoMakeup'},
            {name: '拼版中', id: 'InMakeup'},
            {name: '已拼版', id: 'Makeuped'}
        ];
        $scope.selectSupplier =function(data){
            vm.tableData.makeupId = "";
            if(data){
                vm.tableData.supId = data.id;
            }else {
                vm.tableData.supId = "";
            }
        };


        $scope.view = function(){
            if ($scope.checked.length == 0|| $scope.checked.length>1) {
                MessageService.error("请勾选一个查看详情");
                return;
            }
            $uibModal.open({
                templateUrl: 'app/outsourcing/order-list.html',
                controller: 'MakeUpOrderListController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        var _obg = {
                            sourceId: $scope.checked[0],
                        };
                        return _obg;
                    },
                    entity: ['SalesOrder','$stateParams', function(SalesOrder, $stateParams) {
                        return SalesOrder.getByOrderMakeupFileId({orderMakeupFileId : $scope.checked[0]}).$promise;
                    }]
                }
            }).result.then(function (result) {
            });
        }

        vm.conditionList = [
            {name:'全部', id:""},
            {name: '未拼版', id: 'NoMakeup'},
            {name: '拼版中', id: 'InMakeup'},
            {name: '已拼版', id: 'Makeuped'}
        ];
        vm.isFilter = false;

        vm.predicate = 'createdDate';
        vm.resolve = false;

        vm.tableData = {
            sort: [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')],
            page: Number($location.search().page) || 0,
            size: 20,
            makeupStatus: $location.search().makeupStatus || '',
            startDate: $location.search().startDate || '',
            endDate: $location.search().endDate || '',
            supId: $location.search().supId || '',
            makeupId: Number($location.search().makeupId) || '',
            keyword: $location.search().keyword || ''
        };
        angular.element("#startDate").val($location.search().startDate || GetDateStr(-31));
        angular.element("#endDate").val($location.search().endDate || GetDateStr(0));
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        vm.isFilter = vm.tableData.supId || vm.tableData.makeupId || vm.tableData.back ? true : false;
        
        Supplier.getAll("", function (res) {
            vm.suppliers = res.filter(function(item){return item.enable;});
        });

        $scope.selectOne = function () {
            angular.forEach(vm.orderMakeupFiles , function (item) {
                var index = $scope.checked.indexOf(item.id);
                if(item.checked && index === -1) {
                    $scope.checked.push(item.id);
                } else if (!item.checked && index !== -1){
                    $scope.checked.splice(index, 1);
                };
            });
        };

        $scope.sort = function () {
            vm.tableData.sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            init();
        }

        Makeup.query({}, function (res, headers) {
            vm.makeups = res;
        }, function (error) {
        });



        init();
        function init() {
            $scope.checked = [];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            
            
            $location.search({
                'page':vm.tableData.page,
                'makeupStatus':vm.tableData.makeupStatus,
                'startDate':vm.tableData.startDate,
                'endDate':vm.tableData.endDate,
                'supId': vm.tableData.supId,
                'makeupType': vm.tableData.makeupId,
                'keyword': vm.tableData.keyword
            });
            OrderMakeupFile.page(vm.tableData, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                angular.forEach(res , function (e) {
                    if (e.filePath) {
                        e.filePath = decodeURI(e.filePath);
                        // if (e.filePath.length>20) {
                        //     e.filePathStr = e.filePath.substring(34);
                        // }
                    }
                });
                vm.orderMakeupFiles = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.conditionSelect = function(data){
            vm.tableData.makeupStatus = data.id;
            vm.tableData.page = 0;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.reset = function(){
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                makeupStatus:'',
                startDate: '',
                endDate: '',
                supId: '',
                makeupId: '',
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            vm.supplier = "";
            init();
        };
        

        //   撤销
        $scope.revoke = function(){
            //获取未分配订单个数
            if($scope.checked.length >0) {
                var _arr = [];
                for(var i=0; i<vm.orderMakeupFiles.length; i++){
                    for(var j=0; j<$scope.checked.length; j++){
                        if($scope.checked[j] == vm.orderMakeupFiles[i].id){
                            _arr.push(vm.orderMakeupFiles[i])
                        }
                    }
                }
                for(var k=0; k<_arr.length; k++){
                    if(_arr[k].makeupStatus == 'InMakeup'){
                        MessageService.error("该订单不能撤销");
                        return
                    }
                }
                MessageService.confirm("确认要撤销该订单？", function () {
                    SalesOrder.noAllotMakeUpId({makeupFileIds: $scope.checked.join(',')}, function () {
                        MessageService.success("撤销成功");
                        init();
                    }, function (error) {
                        MessageService.error("撤销失败，请重试！");
                    });
                });
            }else{
                MessageService.error("请选择已分配的订单撤销");
            }
        }

        vm.makeupStatus = makeupStatus;
         function makeupStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "NoMakeup":
                    return "未拼版";
                case "Makeuped":
                    return "已拼版";
                case "InMakeup":
                    return "拼版中";
                case "NotRequiredMakeup":
                    return "不拼版";
                default:
                    return "没有对应的状态";
            }
        }
        
    }
})();
