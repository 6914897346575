/**
 * Created by 小猪猪lover on 2019/1/10.
 */
(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('importStockUploadList', {
                parent: 'module',
                url: '/importStock/{type}',
                data: {
                    authorities: ['ROLE_PRODUCT_WAREHOUSE']
                },
                views: {
                    'content': {
                        templateUrl: 'app/reserveUpload/importStock-upload-list.html',
                        controller: 'ImportStockUploadListController',
                        controllerAs: 'vm'
                    }
                }
            })

    }
})();
