(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SuppliesController', SuppliesController);

    SuppliesController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks', '$uibModal','$uibModalInstance', 'entity', 'PropertyConfigs'];

    function SuppliesController ($scope, Principal, LoginService, $state,User,MessageService, ProductCategory, ParseLinks, $uibModal, $uibModalInstance, entity, PropertyConfigs) {
        var vm = this;

        vm.category = entity.category || 'MaterialProduct';
        if(entity){
            vm.data = {
                id: entity.materialSettingId,
            }
        }else {
            vm.data = {}
        }
        vm.tableData = {
            page: 0,
            size: 10,
            sort: ['id,DESC'],
            keyword: '',
            categoryId: ''
        };
        //获取品类
        vm.categoryList = ProductCategory.getAllCategory();

        $scope.categoryTitle = function(data, $event){
            data.open = !data.open ? true : false;
            $event.stopPropagation();
        }
        $scope.categoryOne = function(data){
            vm.tableData.categoryId = data ? data.id : "";
            init()
        }
        $scope.categoryChild = function(data){
            vm.tableData.categoryId = data.id;
            init()
        }

        init();
        function init() {
            vm.tableList = [];
            var items = [{key:"product.category",op:"=",value:vm.category},{key:"product.enable",op:"=",value:true}];
            if(vm.tableData.categoryId){
                items.push({key:'product.productCategory.id',op:'=',value: vm.tableData.categoryId})
            }
            PropertyConfigs.getPageByCondition({
                items: items,
                page: vm.tableData.page,
                size: vm.tableData.size,
                sort: vm.tableData.sort
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.tableList =res;
                vm.queryCount = headers('X-Total-Count');
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        //选择表格
        $scope.tableCb = function(data){
            vm.data = data;
        }

        //确认
        $scope.save = function () {
            $uibModalInstance.close(vm.data);
        }
        //取消
        $scope.modalHide = function () {
            $uibModalInstance.close(false);
        }
        $scope.categoryInfo = function (item){
            switch (item) {
                case 'OrderingProduct':
                    return '定制品';
                case 'GeneralProduct':
                    return '成品';
                case 'MaterialProduct':
                    return '辅料';
                default:
                    return item
            }
        }
    }
})();
