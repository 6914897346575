(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('outsourcingTask', {
                parent: 'module',
                url: '/outsourcingTask',
                data: {
                    authorities: ['ROLE_ENTRUST']
                },
                views: {
                    'content': {
                        templateUrl: 'app/outsourcing/outsourcing-task.html',
                        controller: 'OutsourcingTaskController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })
            .state('prePress', {
                parent: 'module',
                url: '/prePress',
                data: {
                    authorities: ['ROLE_PREPRESS']
                },
                views: {
                    'content': {
                        templateUrl: 'app/outsourcing/pre-press.html',
                        controller: 'PrePressController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })
            .state('makeUp', {
                parent: 'module',
                url: '/makeUp',
                data: {
                    authorities: ['ROLE_IMPOSITION']
                },
                views: {
                    'content': {
                        templateUrl: 'app/outsourcing/make-up.html',
                        controller: 'MakeUpController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })
            .state('expandTask', {
                parent: 'module',
                url: '/expandTask',
                data: {
                    authorities: ['ROLE_OUTPAINT']
                },
                views: {
                    'content': {
                        templateUrl: 'app/outsourcing/expand-task.html',
                        controller: 'ExpandTaskController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('orderSource');
                        return $translate.refresh();
                    }]
                }
            })
        ;

    }
})();
