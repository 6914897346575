(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CustomerHubSkuController', CustomerHubSkuController);

    CustomerHubSkuController.$inject = ['$scope', 'msgdata', '$uibModalInstance', 'MessageService', 'Product', '$q', 'PrePressFlow','$uibModal', 'ProofRule'];

    function CustomerHubSkuController ($scope, msgdata, $uibModalInstance, MessageService, Product, $q, PrePressFlow,$uibModal, ProofRule) {
        var vm = this;

        console.log(msgdata.customerSkuManages)
        // return
        vm.clear = clear;
        vm.save = save;
        vm.skuManages = [];
        vm.delSku = [];
        vm.addSku = [];
        vm.product = Product.get({id: msgdata.productId});
        // 印前处理流程
        // vm.prePressList = PrePressFlow.getAll();
        PrePressFlow.getAll({},function(res){
            vm.prePressList = res.filter(function(item){return item.enable});
        });


        ProofRule.getAll({},function(res){
            vm.proofRules = res.filter(function(item){return item.enable});
        });

        $q.all([vm.product.$promise]).then(function(){
            vm.product.propertyConfigs.sort(function(a, b) {
                return a.sort - b.sort
            })
            vm.propertyList = vm.product.propertyConfigs;
            vm.addSku = angular.copy(vm.propertyList);
            if (!!msgdata.customerSkuManages) {
                for (var i = msgdata.customerSkuManages.length - 1; i >= 0; i--) {
                    var skuManage ={
                        prePress: msgdata.customerSkuManages[i].prePress,
                        prePressFlowId: msgdata.customerSkuManages[i].prePressFlowId,
                        needCheck: msgdata.customerSkuManages[i].needCheck,
                        proofRuleId: msgdata.customerSkuManages[i].proofRuleId,
                        propertyConfigId: msgdata.customerSkuManages[i].propertyConfigId,
                        customerProductManageId: msgdata.customerSkuManages[i].customerProductManageId,
                        delete:msgdata.customerSkuManages[i].delete,
                        cancelled:msgdata.customerSkuManages[i].cancelled
                    };
                    vm.skuManages.push(skuManage);
                }

                for (var x = vm.skuManages.length - 1; x >= 0; x--) {
                    for (var y = vm.propertyList.length - 1; y >= 0; y--) {
                        if (vm.propertyList[y].id == vm.skuManages[x].propertyConfigId) {
                            vm.skuManages[x].propertyConfig = vm.propertyList[y];
                            vm.propertyList[y].isAdd = true;
                        }
                    }

                    if(vm.skuManages[x].delete||vm.skuManages[x].cancelled){
                        vm.delSku.push(vm.skuManages[x])
                    }
                }

                for (var y = vm.propertyList.length - 1; y >= 0; y--) {
                    if(vm.propertyList[y].isAdd){
                        vm.addSku.splice(y, 1);
                    }
                }
                if (vm.addSku.length) {
                    angular.forEach(vm.addSku, function (sku) {
                        var sku2 = {
                            propertyConfigId: sku.id,
                            propertyConfig:sku,
                            delete:true,
                            cancelled:true,
                            prePress:true,
                            needCheck:true,
                            prePressFlowId:null,
                            proofRuleId:null,
                        }
                        vm.skuManages.push(sku2);
                        vm.delSku.push(sku2);
                    })
                }
                console.log(vm.addSku)
                console.log(vm.delSku)
            }else{
                angular.forEach(vm.propertyList,function (p) {
                    // console.log(p);
                    var skuManage = {
                        propertyConfigId: p.id,
                        propertyConfig:p,
                        prePress:true,
                        needCheck:true,
                        prePressFlowId:null,
                        proofRuleId:null,
                    }
                    vm.skuManages.push(skuManage);
                })
            }
            console.log(vm.skuManages)
        });

        $scope.changePropertyConfig = function(data){
            // data.propertyConfigId = data.propertyConfig.id
        };

        function clear(){
            $uibModalInstance.dismiss();
        }

        function save() {
            vm.isSaving = true;
            var skuManageList = [];
            var count = 0,count2 = 0;

            for (var i = vm.skuManages.length - 1; i >= 0; i--) {
                var skuManageObj ={
                    propertyConfigId: vm.skuManages[i].propertyConfigId,
                    prePress:vm.skuManages[i].prePress,
                    prePressFlowId:vm.skuManages[i].prePressFlowId,
                    needCheck:vm.skuManages[i].needCheck,
                    proofRuleId:vm.skuManages[i].proofRuleId,
                    delete:vm.skuManages[i].delete,
                    cancelled:vm.skuManages[i].cancelled
                };

                if(vm.skuManages[i].prePress&&!vm.skuManages[i].prePressFlowId){
                    count++;
                }
                if(vm.skuManages[i].needCheck&&!vm.skuManages[i].proofRuleId){
                    count2++;
                }
                skuManageList.push(skuManageObj);
            }
            console.log(count)
            if(count>0){
                vm.isSaving = false;
                MessageService.error("请选择印前处理流程");
                return
            }
            if(count2>0){
                vm.isSaving = false;
                MessageService.error("请选择校验方式");
                return
            }
            console.log(vm.skuManages)
            console.log(skuManageList)
            $uibModalInstance.close(skuManageList);
        }

        //添加商品管理
        $scope.skuCopy = function(data){
            // var skuManage ={
            //     propertyConfig: vm.skuManages[i].propertyConfig,
            //     prePress:vm.skuManages[i].prePress,
            //     prePressFlow:vm.skuManages[i].prePressFlow,
            // };
            //
            // vm.flag = false;
            // angular.forEach(vm.skuManages, function(item){
            //     if(!item.makeupId || !item.capacity || !item.propertyId || !item.deviceId){
            //         vm.flag = true;
            //     }
            // });
            // if(vm.flag){
            //     MessageService.error("请完善空白项，再追加");
            // }else{
            //     vm.skuManages.push(angular.copy(skuManage));
            // }
            $uibModal.open({
                templateUrl: 'app/customerHub/customerHub-copy.html',
                controller: 'CustomerHubCopyController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    msgdata: function () {
                        return {
                            skuManages:vm.skuManages,
                            data:data
                        }
                    }
                }
            }).result.then(function(result){
                // console.log(result);
                angular.forEach(result.skuManages,function(item,i){
                    if(item.selected){
                        // vm.skuManages[i].prePressFlow = angular.copy(item.prePressFlow);
                        vm.skuManages[i].prePressFlowId = angular.copy(item.prePressFlowId);
                        vm.skuManages[i].prePress = angular.copy(item.prePress);
                        vm.skuManages[i].proofRuleId = angular.copy(item.proofRuleId);
                        vm.skuManages[i].needCheck = angular.copy(item.needCheck);
                    }
                });
                console.log(vm.skuManages);
            });
        };

        $scope.skuDelete = function(index){
            vm.delSku.push(vm.skuManages[index]);
            vm.skuManages[index].delete = true;
            vm.skuManages[index].cancelled = true;
            vm.skuManages[index].prePress = false;
            vm.skuManages[index].prePressFlowId = null;
            vm.skuManages[index].proofRuleId = null;
            vm.skuManages[index].needCheck = false;
        };

        $scope.addSkuManage = function () {
            for(var i = 0;i<vm.skuManages.length;i++){
                var sku = vm.skuManages[i];
                if(sku.propertyConfigId==vm.delSku[vm.delSku.length-1].propertyConfigId){
                    sku.delete = false;
                    sku.cancelled = false;
                    vm.delSku.splice(vm.delSku.length-1,1);
                    break
                }
            }
        }
    }
})();
