(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InBoundController', InBoundController);

    InBoundController.$inject = ['$scope', 'Principal', 'OutBound', '$state','User','MessageService', 'ParseLinks', 'SalesOrder',
    '$uibModal', 'Supplier','Product', '$localStorage','$http', '$stateParams', '$rootScope', '$location', 'DateUtils'];

    function InBoundController ($scope, Principal, OutBound, $state,User,MessageService, ParseLinks, SalesOrder,
        $uibModal, Supplier, Product, $localStorage, $http, $stateParams, $rootScope, $location, DateUtils) {
        var vm = this;

        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];

        vm.conditionList = [
            {name:'全部', value:''},
            {name:'待提交', value:'UnShippedOut'},
            {name:'已完成', value:'Finish'},
            {name:'已关闭', value: 'Close'},
        ];
        vm.predicate = 'createdDate';
        vm.resolve = false;

        vm.page = $stateParams.page || $location.search().page;
        vm.tableData = {
            sort: [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')],
            page: Number(vm.page) || 0,
            size: 20,
            storeName: $stateParams.storeName || $location.search().storeName || '',
            state:$stateParams.state || $location.search().state || '',
            stockOrderNumber:$stateParams.stockOrderNumber || $location.search().stockOrderNumber || '',
            startDate: $stateParams.startDate || $location.search().startDate || GetDateStr(-31),
            endDate: $stateParams.endDate || $location.search().endDate || GetDateStr(0),
            back: $stateParams.back || $location.search().back || '',
            number: $stateParams.number || $location.search().number || ''
        };
        angular.element("#startDate").val($stateParams.startDate || $location.search().startDate || GetDateStr(-31));
        angular.element("#endDate").val($stateParams.endDate || $location.search().endDate || GetDateStr(0));

        init();
        function init() {
            vm.tablePitch = '';
            $scope.select_all = false;
            $scope.checked =[];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            $location.search({
                'page':vm.tableData.page,
                'state':vm.tableData.state,
                'stockOrderNumber':vm.tableData.stockOrderNumber,
                'startDate':vm.tableData.startDate,
                'storeName':vm.tableData.storeName,
                'number':vm.tableData.number,
                'endDate':vm.tableData.endDate,
                'back':vm.tableData.back
            });
            var items = []


            if(vm.tableData.stockOrderNumber){
                items.push({key:'stockOrderNumber',op:'=',value:vm.tableData.stockOrderNumber})
            }
            if(vm.tableData.number){
                items.push({key:'number',op:'=',value:vm.tableData.number})
            }
            if(vm.tableData.storeName){
                items.push({key:'storeName',op:'like',value:vm.tableData.storeName})
            }
            if (vm.tableData.state) {
                items.push({key: "status", op: "=", value: vm.tableData.state});
            };
            if (vm.tableData.startDate) {
                items.push({key: "businessTime", op: ">=", value: DateUtils.convertLocalDateToServer(vm.tableData.startDate)+"T00:00:00.000Z"});
            };
            if (vm.tableData.endDate) {
                items.push({key: "businessTime", op: "<=", value: DateUtils.convertLocalDateToServer(vm.tableData.endDate)+"T23:59:59.000Z"});
            };
            vm.tableData.items= items
            var _data = {
                page:vm.tableData.page,
                size:vm.tableData.size,
                sort:vm.tableData.sort,
                items:items
            }
            OutBound.byCondition(_data, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.salesOrders = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {
            });
        };

        $scope.conditionSelect = function(data){
            vm.tableData.state = data.value;
            vm.tableData.number = '';
            vm.tableData.page = 0;
            init()
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        $scope.reset = function(){
            vm.isFilter = false;
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                state:'',
                stockOrderNumber:'',
                startDate: '',
                endDate: '',
                back: '',
                number:'',
            };
            vm.supplier = "";
            vm.product = "";
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
            localStorage.removeItem('tableData');
        };


        //导出
        $scope.orderExport = function () {
            $http({
                url: 'api/inventory-stock-out/import',
                method: "POST",
                data: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "出库单.xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            $rootScope.previousState = from;
            $rootScope.previousParams = fromParams;
        });


        $scope.getStatus = function(item){
            switch (item) {
                case 'UnShippedOut':
                    return '未出库';
                case 'ShippedOut':
                    return '已出库';
                case 'ShippedIn':
                    return '已入库';
                case 'Close':
                    return '关闭';
                default:
                    return item
            }
        }

        //日期
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
    }
})();
