(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CommodityController', CommodityController);

    CommodityController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','Product', 'ParseLinks', '$uibModal', 'ProductCategory', 'Makeups', '$timeout', '$stateParams'];

    function CommodityController ($scope, Principal, LoginService, $state,User,MessageService, Product, ParseLinks, $uibModal, ProductCategory, Makeups, $timeout, $stateParams) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_PRODUCT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有商品访问的权限！");
                $state.go("home");
            }
        });
        vm.productType = $stateParams.productType;
        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        // vm.conditionList = [{name:'全部', id:''}];
        vm.typeList = [
            {name:'内部', value:'内部'},
            {name:'供应商', value: '供应商'}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ["lastModifiedDate,DESC"],
            keyword: '',
            categoryId: ''
        };
        vm.productCategorySecond = [];
        ProductCategory.byCondition({items: [{key: 'subordinate', op: '=', value: false}, {key: 'enable', op: '=', value: true}]}, function(value){
            vm.productCategoryFirst =  value;

        })

        $scope.changeSearchCategoryFirst = function(){
            vm.productCategorySecond = [];
            if (vm.tableData.parentCategoryId == null) {
                return;
            }
            for(var i = 0 ; i< vm.productCategoryFirst.length; i++){
                var c =  vm.productCategoryFirst[i];
                //获取二级分类
                if (c.id == vm.tableData.parentCategoryId) {
                    ProductCategory.byCondition({items:
                        [{key: 'subordinate', op: '=', value: true}, {key: 'enable', op: '=', value: true},{key: 'productCategory.id', op: '=', value: c.id}]
                    }, function (res) {
                        vm.productCategorySecond = res;
                    });
                }
            }
        }

        init()
        function init() {
            vm.tableList = [];
            var items = [{key:"category",op:"=",value:$stateParams.productType}];
            if(vm.tableData.keyword){
                items.push({key:"name",op:"like",value:vm.tableData.keyword})
            }
            if(vm.tableData.categoryId){
                items.push({key:"productCategory.id",op:"=",value:vm.tableData.categoryId})
            }
            Product.byCondition({
                items: items,
                page: vm.tableData.page,
                size: vm.tableData.size,
                sort: vm.tableData.sort
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                for(var i=0;i<res.length;i++){
                    for(var j=0;j<res[i].productCirculations.length; j++){
                        if(res[i].productCirculations[j].defaulted){
                            res[i].productCirculationsList = res[i].productCirculations[j];
                        }
                    }
                }
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };
        $scope.conditionSelect = function(data){
            vm.tableData.categoryId = data.id;
            vm.tableData.page = 0;
            init();
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        $scope.clear = function(){
            vm.tableData = {
                page: Number($stateParams.page) || 0,
                size: 20,
                sort: ["lastModifiedDate,DESC"],
                keyword: '',
                categoryId: ''
            };
            init();
        }
        //删除
        $scope.roleDelete = function (data) {
            MessageService.confirm("确认要删除该商品？", function () {
                Product.delete({id: data.id}, function () {
                    init();
                    MessageService.success("商品删除成功");
                }, function (error) {
                    MessageService.error("删除失败，请重试！");
                });
            });
        };

        $scope.tableStateBtn = function () {
            var _h = angular.element(".table-top").height();
            if(_h > 40){
                angular.element(".table-top").height("40");
                angular.element(".table-state-btn").removeClass("table-state-btnOn");
            }else {
                angular.element(".table-top").height("auto");
                angular.element(".table-state-btn").addClass("table-state-btnOn");
            }
        }
        window.onresize = function(){
            var _h = angular.element(".table-top").find(".table-state").height();
            $timeout(function () {
                if(_h > 40){
                    vm.isTableState = true;
                }else {
                    vm.isTableState = false;
                }
            })
        }
        $scope.productTypeInfo = function (item){
            switch (item) {
                case 'OrderingProduct':
                    return '定制品';
                case 'GeneralProduct':
                    return '成品';
                case 'MaterialProduct':
                    return '辅料';
                default:
                    return item
            }
        }
    }

})();
