(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('OutBound', OutBound)
        .factory('FindWarehouse', FindWarehouse)
        .factory('stockDiffs', stockDiffs)
        .factory('refundGoods', refundGoods)

        OutBound.$inject = ['$resource'];
        FindWarehouse.$inject = ['$resource'];
        stockDiffs.$inject = ['$resource'];
        refundGoods.$inject = ['$resource'];

    function OutBound ($resource) {
        var resourceUrl =  'api/inventory-stock-out/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'close': {
                method: 'GET',
                params: {
                    path: 'close'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'outBound': {
                method: 'PUT'
            },
            "byCondition":{
                method:"POST",
                params:{
                    id:"byCondition"
                },
                isArray: true
            },
            'checkDiff': {
                method: 'GET',
                params: {
                    path: 'checkDiff'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
    // 查库存
    function FindWarehouse ($resource) {
        var resourceUrl =  'api/reserves/findByWarehouseIdAndProductNumber/:path/:id';

        return $resource(resourceUrl, {}, {
            'searchByidAndNumber': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }

    function stockDiffs ($resource) {
        var resourceUrl =  'api/inventory-stock-diffs/:path/:id';
        return $resource(resourceUrl, {}, {
            'searchByidAndNumber': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    id:"byCondition"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'confirm': {
                method: 'GET',
                params: {
                    path: 'confirm'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
    function refundGoods ($resource) {
        var resourceUrl =  'api/inventory-refunds/:path/:id';
        return $resource(resourceUrl, {}, {
            'searchByidAndNumber': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    id:"byCondition"
                },
                isArray: true
            },
            'close': {
                method: 'GET',
                params:{
                    path:"close"
                }
            },
            'get': {
                method: 'GET'
            },
            'confirm': {
                method: 'PUT'
            },
        });
    }

})();
