function printLodop(MessageService,DeliverySettings,html,proview){
    if(LODOP){
        var width = 210;
        var height = 210;
        DeliverySettings.get({},function (res){
            if(res.templateSize){
                if(res.templateSize.split("x")[0]){
                    width = res.templateSize.split("x")[0];
                }
                if(res.templateSize.split("x")[1]){
                    height = res.templateSize.split("x")[1];
                }
            }
            LODOP.SET_LICENSES("","2A0562B5A4A7A585F7300EBFD8D3AE0B5E4","","");
            LODOP.PRINT_INIT("");
            LODOP.SET_PRINT_PAGESIZE(3,width+"mm",10,"") // 纸张方向大小
            LODOP.ADD_PRINT_HTM(0,0,width+"mm","100%",html);
            if(proview){
                LODOP.PREVIEW() // 打印预览
            }else{
                LODOP.PRINT()    //直接打印
            }
        })
    }else{
        MessageService.error("请检查C-Lodop是否启用");
    }
}
