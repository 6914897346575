(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('TagDetailController', TagDetailController);

    TagDetailController.$inject = ['$scope', '$state','MessageService', 'MetaTags', 'entity', '$stateParams','LocationProvice', 'LocationCity', 'LocationDistrict','Supplier','Qinius','$q','$sce'];

    function TagDetailController ($scope,$state,MessageService,MetaTags, entity, $stateParams,LocationProvice, LocationCity, LocationDistrict,Supplier,Qinius, $q,$sce) {
        var vm = this;
        vm.ratio = 0;
        js_getDPI();
        if(!$stateParams.id){
            vm.data = {
                tags:[]
            };
        }else {
            vm.data = entity;
            $q.all([vm.data.$promise]).then(function () {
                vm.data.tags.forEach(function (item) {
                    item.width = transitionPx(item.width);
                    item.height = transitionPx(item.height);
                    item.x = transitionPx(item.x);
                    item.y = transitionPx(item.y);
                })
                ratioInfo();
            })
        }
        Supplier.getAll({}, function (res) {
            vm.suppliers =res.filter(function(item){return item.enable == true;});
        })

        $scope.$watch("vm.data.width",function(newValue,oldValue){
            if(newValue > 0){
                ratioInfo()
            }
        })
        $scope.$watch("vm.data.height",function(newValue,oldValue){
            if(newValue > 0){
                ratioInfo()
            }
        })
        function ratioInfo(){
            if(vm.data.width <= 0 && vm.data.height <= 0){
                return
            }
            vm.width = angular.copy(transitionPx(vm.data.width));
            vm.height = angular.copy(transitionPx(vm.data.height));
            vm.windowWidth = 500;
            vm.windowHeight = 500;
            var ratio = vm.width / vm.height;
            var ratio1 = vm.windowWidth / vm.windowHeight;
            if (ratio >= ratio1) {
                vm.ratio = vm.windowWidth / vm.width;
            } else {
                vm.ratio = vm.windowHeight / vm.height;
            }
        }

        $scope.contentChange = function(item, type){
            if(item){
                var _data = {
                    type: type,
                    width:100,
                    height:50,
                    x: 5,
                    y: 5
                }
                if(type == 'altek'){ _data.width = 72; _data.height = 12; _data.name = '客户简介' };
                if(type == 'customer'){  _data.width = 138; _data.height = 12; _data.name = '客户全名' };
                if(type == 'orderNumber'){  _data.width = 69; _data.height = 12; _data.name = '订单号' };
                if(type == 'recipients'){  _data.width = 43; _data.height = 12; _data.name = '收件人' };
                if(type == 'quantity'){  _data.width = 32; _data.height = 13; _data.name = '份数' };
                if(type == 'productName'){  _data.width = 173; _data.height = 14; _data.name = '商品名称'};
                if(type == 'barCode'){  _data.width = 148; _data.height = 52; _data.name = '来源单号条码'};
                if(type == 'sourceOrderNumber'){  _data.width = 69; _data.height = 12; _data.name = '来源单号'};
                if(type == 'phone'){  _data.width = 69; _data.height = 12; _data.name = '收件人电话'};
                if(type == 'articleName'){  _data.width = 100; _data.height = 8; _data.name = '作品名'};
                if(type == 'storeName'){  _data.width = 100; _data.height = 8; _data.name = '店铺名称'};
                if(type == 'orderNumber'){  _data.width = 69; _data.height = 8; _data.name = '订单号'};
                _data.width = _data.width;
                _data.width = _data.width;
                _data.height = _data.height;
                vm.data.tags.push(_data);
            }else {
                var index = vm.data.tags.map(function (item){return item.type}).indexOf(type);
                vm.data.tags.splice(index, 1)
            }
        }

        vm.isDown = false;
        $scope.tagdown = function ($event, item) {
            fn($event)
            vm.tagChartlet = {};
            vm.tagChartlet.x = $event.clientX;
            vm.tagChartlet.y = $event.clientY;
            vm.tagChartlet.l = angular.copy(item.x * vm.ratio);
            vm.tagChartlet.t = angular.copy(item.y * vm.ratio);
            vm.tagChartlet.r = angular.copy((vm.width - item.width) * vm.ratio);
            vm.tagChartlet.b = angular.copy((vm.height - item.height) * vm.ratio);
            vm.isDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (!vm.isDown) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - (vm.tagChartlet.x - vm.tagChartlet.l);
                var nt = ny - (vm.tagChartlet.y - vm.tagChartlet.t);
                if(nl<0){ nl = 0};
                if(nl > vm.tagChartlet.r){ nl = vm.tagChartlet.r};
                if(nt < 0){ nt = 0};
                if(nt > vm.tagChartlet.b){ nt = vm.tagChartlet.b};

                item.x = nl / vm.ratio;
                item.y = nt / vm.ratio;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                vm.isDown = false;
                $event.stopPropagation();
            }
        }
        $scope.save = function () {
            var _data = angular.copy(vm.data);
            if(!_data.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(!_data.supplierId){
                MessageService.error("供应商不能为空");
                return;
            }
            if(!_data.width){
                MessageService.error("尺寸宽不能为空");
                return;
            }
            if(!_data.height){
                MessageService.error("尺寸高不能为空");
                return;
            }
            _data.tags.forEach(function (item) {
                item.width = transitionMm(item.width);
                item.height = transitionMm(item.height);
                item.x = transitionMm(item.x);
                item.y = transitionMm(item.y);
            });
            vm.isSaving = true;
            if(!$stateParams.id){
                MetaTags.add(_data, onSaveSuccess, onSaveError);
            }else {
                MetaTags.update(_data, onSaveSuccess, onSaveError);
            }
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("成功");
                $state.go("tag",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("tag",{page: $stateParams.page});
        };


        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            } else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[1];
            return arrDPI;
        }
        //cm转px
        function transitionPx(item) {
            return parseFloat((item * vm.windowDpi / 25.4 * 10).toFixed(2));
        }

        //px转cm
        function transitionMm(item) {
            return parseFloat((item * 25.4 / vm.windowDpi / 10).toFixed(2));
        }
    }
})();
