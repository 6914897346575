(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchaseOrderPreviewController', PurchaseOrderPreviewController);

    PurchaseOrderPreviewController.$inject = ['$scope','$state', '$q', 'MessageService', 'Reserves', 'PurchaseProductLine', 'Supplier', 'Depository', 'UnitMeasurement', 'Product', 'PurchaseOrder', 'entity','$uibModal'];

    function PurchaseOrderPreviewController ($scope, $state, $q, MessageService, Reserves, PurchaseProductLine, Supplier, Depository, UnitMeasurement, Product, PurchaseOrder, entity,$uibModal) {
        var vm = this;
        vm.purchaseOrder = entity;
        $scope.goback = function (){
            history.back();
        };
        $scope.preview = function (){
            var iframe = document.getElementById('print-frame');
            if (iframe === null || iframe === undefined) {
                iframe = document.createElement('iframe');
                iframe.lang = 'en';
                iframe.style.display = 'none';
                iframe.id = 'print-frame';
                document.body.insertBefore(iframe, document.body.children[0]);
                iframe.contentDocument.head.innerHTML = "<meta charset='UTF-8'><title></title><link rel='stylesheet' href='content/css/main.css'><link rel='stylesheet' href='bower_components/bootstrap/dist/css/bootstrap.css'>";
            }

            setTimeout(function () {
                var changePrint = function (type) {
                    var html = '';
                    type = type?type:1;
                    if(type=='1'){
                        html='<style type="text/css" media="print">\n' + '  @page { size: landscape; }\n' + '</style>';
                    }else{
                        html='<style type="text/css" media="print">\n' + '  @page { size: portrait; }\n' + '</style>';
                    }
                    return html;
                };
                $('#previewId').append(changePrint(1));
                iframe.contentDocument.body.innerHTML = document.getElementById("previewId").innerHTML;
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            }, 300);
        }
    }
})();
