(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OutBoundPreviewController', OutBoundPreviewController);

    OutBoundPreviewController.$inject = ['$scope','$state', '$q', 'MessageService', 'entity'];

    function OutBoundPreviewController ($scope, $state, $q, MessageService, entity) {
        var vm = this;
        vm.data = entity;
        vm.nums = 0;
        vm.outNums = 0;
        $q.all([vm.data.$promise]).then(function(){
            if(vm.data.lines.length>0){
                vm.data.lines.forEach(function (item){
                    if(!item.cancelled){
                        vm.nums += item.num;
                        vm.outNums += item.outNum;
                    }
                })
            }
        });
        $scope.goback = function (){
            history.back();
        };
        $scope.preview = function (){
            var iframe = document.getElementById('print-frame');
            if (iframe === null || iframe === undefined) {
                iframe = document.createElement('iframe');
                iframe.lang = 'en';
                iframe.style.display = 'none';
                iframe.id = 'print-frame';
                document.body.insertBefore(iframe, document.body.children[0]);
                iframe.contentDocument.head.innerHTML = "<meta charset='UTF-8'><title></title><link rel='stylesheet' href='content/css/main.css'><link rel='stylesheet' href='bower_components/bootstrap/dist/css/bootstrap.css'>";
            }

            setTimeout(function () {
                var changePrint = function (type) {
                    var html = '';
                    type = type?type:1;
                    if(type=='1'){
                        html='<style type="text/css" media="print">\n' + '  @page { size: landscape; }\n' + '</style>';
                    }else{
                        html='<style type="text/css" media="print">\n' + '  @page { size: portrait; }\n' + '</style>';
                    }
                    return html;
                };
                $('#previewId').append(changePrint(1));
                iframe.contentDocument.body.innerHTML = document.getElementById("previewId").innerHTML;
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            }, 300);
        }
    }
})();
