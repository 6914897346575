(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('DataChart', DataChart);

    DataChart.$inject = ['$resource'];

    function DataChart ($resource) {
        var resourceUrl =  'api/sales_order/:path/:id';

        return $resource(resourceUrl, {}, {
            'export': {
                method: 'GET',
                // isArray: true,
                params: {
                    path: 'export'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getData': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getData'
                }
            },
            'getData2': {
                method:'GET',
                isArray: true,
                params: {
                    path: 'getData2'
                }
            }
        });
    }
})();
