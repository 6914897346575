(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderGeneralDetailsController', OrderGeneralDetailsController);

    OrderGeneralDetailsController.$inject = ['$scope',  'Principal',  '$uibModal', '$state','OrderPack','MessageService', 'ParseLinks', '$localStorage','$http', '$stateParams', '$rootScope'];

    function OrderGeneralDetailsController ($scope,  Principal,  $uibModal, $state,OrderPack,MessageService, ParseLinks, $localStorage, $http, $stateParams, $rootScope) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });

        vm.token = $localStorage.authenticationToken;
        vm.data = {}
        vm.changehWarehouse = changehWarehouse;
        vm.reserveList = []

        init()

        function init(){
            OrderPack.get({id : $stateParams.id},function(res){
                vm.data = res
                if(res.status === 'ToDelivery'){
                    getReserve()
                }
            },function(){

            })
        }
        function getReserve(){
            OrderPack.fetchReserve({id : $stateParams.id},function(res){
                vm.data.salesOrders.forEach(function(itm){
                    res.lines.forEach(function(item){
                        if(itm.id === item.id && item.reserves.length){
                            // 按仓库数量倒序排列
                            item.reserves.sort(function(a,b){
                                return b.quantity - a.quantity
                            })
                            itm.reserves =  item.reserves
                            // 默认选中第一个仓库
                            itm.warehouseId = item.reserves[0].warehouseId
                            itm.warehouseName = item.reserves[0].warehouseName
                            itm.warehouseQuantity = item.reserves[0].quantity
                        }
                    })
                })

            },function(res){
                console.log(res);
            })
        }
        //打印
        // $scope.printpage = function () {
        //     var iframe = document.getElementById('print-frame');
        //     if (iframe === null || iframe === undefined) {
        //         iframe = document.createElement('iframe');
        //         iframe.lang = 'en';
        //         iframe.style.display = 'none';
        //         iframe.id = 'print-frame';
        //         document.body.insertBefore(iframe,document.body.children[0]);
        //         iframe.contentDocument.head.innerHTML = "<meta charset='UTF-8'><title>主板文件</title><link rel='stylesheet' href='bower_components/bootstrap/dist/css/bootstrap.css'><link rel='stylesheet' href='content/css/main.css'>";
        //     }
        //
        //     setTimeout(function () {
        //         iframe.contentDocument.body.innerHTML = document.getElementById("printPage").innerHTML;
        //         iframe.contentDocument.getElementById("printHide").style.display="none";
        //         iframe.contentDocument.getElementById("printHide1").style.display="none";
        //         iframe.contentWindow.focus();
        //         iframe.contentWindow.print();
        //     },300);
        // };


        $scope.printpageBtn = function(){

            $http({
                url: 'api/sales_order/downloadJustifyFiles',
                method: "GET",
                params: {id: vm.salesOrder.id},
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var fileName = vm.salesOrder.number + (!vm.salesOrder.sku ? "" :"_" + vm.salesOrder.sku) + (!vm.salesOrder.propertySku ? "" : "_" + vm.salesOrder.propertySku);
                var blob = new Blob([data], {type: "application/zip"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                a.setAttribute('download', fileName);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (error, status) {
                if(status == 400){
                    MessageService.error("组版文件不存在");
                }
            });
        };
    //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    number: $stateParams.number,
                    logisticsNumber: $stateParams.logisticsNumber,
                    source: $stateParams.source,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    timeWarning: $stateParams.timeWarning,
                    replenishment: $stateParams.replenishment,
                    refund: $stateParams.refund,
                    supplierId: $stateParams.supplierId,
                    productId: $stateParams.productId,
                    keyword: $stateParams.keyword,
                    promoteProduct: $stateParams.promoteProduct
                });
            }else {
                history.back();
            }
        };

        $scope.editAddress = function(){
            $uibModal.open({
                templateUrl: 'app/order/order-address.html',
                controller: 'OrderAddressController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.data
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    // $state.reload();
                    init()
                }
            });
        };

        //发货
        $scope.delivery = function () {
            if(!validQuantity())return
            $uibModal.open({
                templateUrl: 'app/order/delivery-dialog.html',
                controller: 'DeliveryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return vm.data;
                    },
                    isDetail:function(){
                        return true
                    }
                }
            }).result.then(function(result){
                if(result){
                    init();
                }
            });
        };

        // 仓库下拉框change时触发， 将仓库名称和库存赋值
        function changehWarehouse(data,index){
            if(!data.warehouseId){
                data.warehouseQuantity  = ''
                return
            }
            var currentWarehouse = data.reserves.find(function(item){
               return item.warehouseId === data.warehouseId
            })
            data.warehouseName = currentWarehouse.warehouseName
            data.warehouseQuantity = currentWarehouse.quantity

            // // 如果库订购数量小于库存 就赋值
            // if(data.quantity <= currentWarehouse.quantity){
            //     data.warehouseName = currentWarehouse.warehouseName
            //     data.warehouseQuantity = currentWarehouse.quantity
            // }else{
            //     data.warehouseName = ''
            //     data.warehouseQuantity = ''
            //     data.warehouseId = ''
            //     MessageService.error('库存不足，请重新选择，当前仓库库存: '+currentWarehouse.quantity)
            //     return
            // }

        }

        // 检查库存是否满足订购数量，满足才能发货
        function validQuantity(){
            var flag = true
            // 必须选择仓库
            var unSelectWarehouse = vm.data.salesOrders.filter(function(item){
                return item.warehouseQuantity == undefined || item.warehouseQuantity === ''
            })
            if(unSelectWarehouse.length > 0){
                MessageService.error('请选择仓库')
                flag = false
                return
            }
            // 库存数量校验
            var warningArr  = []
            vm.data.salesOrders.forEach(function(item){
                 if(item.quantity > Number(item.warehouseQuantity)){
                    warningArr.push(item.productName)
                 }
            })
            var _warningArr = warningArr.join(',')
            if(warningArr.length > 0) {
                flag = false
                MessageService.error(_warningArr + '库存不足，无法发货')
            }
            return flag
            // if(flag){
            //     vm.data.salesOrders.forEach(function(item){
            //         if(!item.warehouseId){
            //             MessageService.error('请选择仓库')
            //             flag = false
            //             return
            //         }
            //         if(item.quantity > item.warehouseQuantity){
            //             MessageService.error('库存不足，请重新选择')
            //             flag = false
            //             return
            //         }
            //     })
            // }
            // return flag
        }

        $scope.getStatus = function(item){
            switch (item) {
                // case 'UnPaid':
                //     return '未支付';
                // case 'Producing':
                //     return '生产中';
                case 'ToDelivery':
                    return '待发货';
                case 'Delivered':
                    return '已发货';
                case 'Received':
                    return '已收货';
                case 'Completed':
                    return '已完成';
                case 'Close':
                    return '已关闭';
                default:
                    return item
            }
        }
    }
})();
