(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('DepositoryAddController', DepositoryAddController);

    DepositoryAddController.$inject = ['$scope','$state','MessageService', 'Depository','LocationProvice', 'LocationCity', 'LocationDistrict'];

    function DepositoryAddController ($scope, $state,MessageService,Depository,LocationProvice, LocationCity, LocationDistrict) {
        var vm = this;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        vm.typeList=[
            {name:'成品库',value:'product'},
            {name:'原辅材料库',value:'accessories'}
        ];
        vm.houseSourceList=[
            {name:'外部',value:'External'},
            {name:'内部',value:'Internal'}
        ];

        vm.data = {
            name:'',
            type:'',
            houseSource:'',
            number:'',
            province:'',
            city:'',
            district:'',
            address:'',
            defaulted:true
        };

        init();
        function init(){
            LocationProvice.query({},function (data) {
                vm.provinces =  data;
            });
        }

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("仓库名称不能为空");
                return;
            }
            if(vm.data.name.length > 20){
                MessageService.error("仓库名称不能大于20位");
                return;
            }
            if(!vm.data.houseSource){
                MessageService.error("仓库属性不能为空");
                return;
            }
            if(!vm.data.type){
                MessageService.error("仓库类型不能为空");
                return;
            }
            if(!vm.data.number){
                MessageService.error("仓库编号不能为空");
                return;
            }
            if (vm.data.number.match(/[\u4E00-\u9FA5]/g,'')) {
                MessageService.error("仓库编号不能输入中文");
                return false;
            }
            if (vm.data.contacts == null || vm.data.contacts == "") {
                MessageService.error("联系人不能为空");
                return false;
            };
            if (vm.data.phone == null || vm.data.phone == "") {
                MessageService.error("联系电话不能为空");
                return false;
            };
            if (vm.province == null || vm.province == "") {
                MessageService.error("请选择省份");
                return false;
            };

            if (vm.city == null || vm.city == '') {
                MessageService.error('请选择城市');
                return false;
            }

            if (vm.district == null || vm.district == "") {
                MessageService.error("请选择区县");
                return false;
            };

            if (vm.data.address == null || vm.address == "") {
                MessageService.error("请输入详细地址");
                return false;
            };

            vm.isSaving = true;
            vm.data.province = vm.province.name;
            vm.data.city = vm.city.name;
            vm.data.district = vm.district.name;

            Depository.add(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("depository");
            }

            function onSaveError (error) {
                console.log(error);
                vm.isSaving = false;
                MessageService.error("添加失败，请检查数据是否合法！");
            }
        };

        function changeProvince() {
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }
    }
})();
