(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Product', Product)
        .factory('PropertyConfigs', PropertyConfigs)
        .factory('ProductCategory', ProductCategory);

    Product.$inject = ['$resource'];
    PropertyConfigs.$inject = ['$resource'];
    ProductCategory.$inject = ['$resource'];

    function Product ($resource) {
        var resourceUrl =  'api/products/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            } ,
            'getNumber': {
                method:'GET',
                params: {
                    id: 'getNumber'
                }
            },
            'getCategoryAndProductDetails': {
                method:'GET',
                params: {
                    id: 'getCategoryAndProductDetails'
                }
            },
            'getNumber2': {
                method:'GET',
                params: {
                    id: 'getNumber2'
                }
            },
            'sortByCategory': {
                method:'GET',
                params: {
                    id: 'sortByCategory'
                }
            },
            'getAllByCategory': {
                method:'GET',
                isArray: true,
                params: {
                    id: 'getAllByCategory'
                }
            },
            'delete':{
                method:'DELETE',
                params: {
                    id: 'id'
                }
            },
            'save': {
                method:'POST'
            },
            'update': {
                method:'PUT'
            },
            'validate': {
                method:'GET',
                params:{
                    id:'exists'
                }
            },
            'getBySkuNumber': {
                method: 'GET',
                params: {
                    id: 'getBySkuNumber'
                }
            },
            'byCondition':{
                method: 'POST',
                isArray: true,
                params: {
                    id: 'byCondition'
                }
            },
            'getAllByCondition':{
                method: 'POST',
                isArray: true,
                params: {
                    id: 'byAllCondition'
                }
            },
            'byProductCategoryId': {
                method: 'GET',
                params: {
                    id: 'byProductCategoryId'
                },
                isArray: true
            }
        });
    };
    function PropertyConfigs ($resource) {
        var resourceUrl =  'api/property-configs/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'page'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            } ,
            'getNumber': {
                method:'GET',
                params: {
                    id: 'getNumber'
                }
            },
            'getCategoryAndProductDetails': {
                method:'GET',
                params: {
                    id: 'getCategoryAndProductDetails'
                }
            },
            'getNumber2': {
                method:'GET',
                params: {
                    id: 'getNumber2'
                }
            },
            'sortByCategory': {
                method:'GET',
                params: {
                    id: 'sortByCategory'
                }
            },
            'getAllByCategory': {
                method:'GET',
                isArray: true,
                params: {
                    id: 'getAllByCategory'
                }
            },
            'delete':{
                method:'DELETE',
                params: {
                    id: 'id'
                }
            },
            'save': {
                method:'POST'
            },
            'update': {
                method:'PUT'
            },
            'validate': {
                method:'GET',
                params:{
                    id:'exists'
                }
            },
            'getBySkuNumber': {
                method: 'GET',
                params: {
                    id: 'getBySkuNumber'
                }
            },
            'getPageByCondition':{
                method: 'POST',
                isArray: true,
                params: {
                    id: 'getPageByCondition'
                }
            },
            'byCondition':{
                method: 'POST',
                isArray: true,
                params: {
                    id: 'byCondition'
                }
            },
            'getAllByCondition':{
                method: 'POST',
                isArray: true,
                params: {
                    id: 'byAllCondition'
                }
            },
            'byProductCategoryId': {
                method: 'GET',
                params: {
                    id: 'byProductCategoryId'
                },
                isArray: true
            }
        });
    };
    function ProductCategory ($resource) {
        var resourceUrl =  'api/product-category/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                params: {
                    id: 'pages'
                }
            },
            'getAll': {
                method:'GET',
                isArray: true
            } ,
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'gets': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete':{
                method:'DELETE',
                params: {
                    id: 'id'
                }
            },
            'save': { method:'POST' } ,
            'update': { method:'PUT' } ,
            'validate': {
                method:'GET',
                params:{
                    id:'exists'
                }
            },
            'getAllSuperior': {
                method: 'GET',
                params: {
                    id: 'getAllSuperior'
                },
                isArray: true
            },
            'getAllCategory': {
                method: 'GET',
                params: {
                    id: 'getAllCategory'
                },
                isArray: true
            },
            'byCondition':{
                method: 'POST',
                isArray: true,
                params: {
                    id: 'byCondition'
                }
            }
        });
    };
})();
