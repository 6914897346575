(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('StockEditController', StockEditController);

    StockEditController.$inject = ['$scope','$state', '$q', 'MessageService', '$stateParams', '$sessionStorage', 'Supplier', 'Depository', 'Reserves', 'Product', 'PurchaseOrder', 'StockManagement','$uibModal'];

    function StockEditController ($scope, $state, $q, MessageService, $stateParams, $sessionStorage, Supplier, Depository, Reserves, Product, PurchaseOrder, StockManagement,$uibModal) {
        var vm = this;
        vm.types = [
            {name:'成品采购', value:'Outsourcing'},
            {name:'材料采购', value:'RawMaterial'}
        ];
        vm.keyword = ''
        vm.data = {
            lines : []
        }
        vm.warehouse = {}
        init()

        function init(){
            // 有仓库id是新建
            if($stateParams.warehouseId){
                vm.data.status = 'INPROGRESS'
                vm.data.warehouseId = $stateParams.warehouseId
                vm.data.operator = $sessionStorage.user.fullname
                vm.data.cancelled = false

                Depository.detail({id : $stateParams.warehouseId},function(res){
                    vm.warehouse = res
                Reserves.page({
                    page:  0,
                    size: 9999999,
                    type: res.type,// 类型。accessories 辅料 / product 成品
                    state:'all',
                    sort: ['createdDate,DESC'],
                    warehouseId: $stateParams.warehouseId
                },function(res1){
                    res1.forEach(function(item){
                        var obj = {
                            // MaterialProduct 辅料 / GeneralProduct 成品
                            productType:item.type === 'product' ? 'GeneralProduct' : 'MaterialProduct',
                            productName:item.productName,
                            productNumber:item.propertyConfig.number,
                            unit:item.measurement.name,
                            sku:item.propertyConfig.sku,
                            quantity:'',
                            beforeQuantity:item.availableQuantity,
                            price:item.price,
                            cancelled:false,
                            remarks:'',
                            skuNumber:item.propertyConfig.skuNumber,
                            reserveId:item.id,
                            propertyConfigId:item.propertyConfig.id,
                            stockTakingOrderId:'',
                            operator:$sessionStorage.user.fullname,

                        }
                        vm.data.lines.push(obj)
                    })
                })
                })
            }else{
                StockManagement.get({id:$stateParams.id},function(res){
                    vm.data = res
                    vm.warehouse.type = res.warehouseType
                    vm.warehouse.name = res.warehouseName
                })
            }
        }

        // vm.suppliers = Supplier.getAll();

        $scope.productAdd = function() {
            var _selectedSku = []
            vm.data.lines.forEach(function(item){
                _selectedSku.push(item.propertyConfigId)

            })
            $uibModal.open({
                templateUrl: 'app/stockManagement/components/product-add-dialog.html',
                controller: 'ProductAddDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return _selectedSku;
                    },
                    productType: function() {
                        return vm.warehouse.type == 'product' ? 'GeneralProduct' : 'MaterialProduct';
                    },
                }
            }).result.then(function(result){
                if(result && result.length){
                    vm.data.lines = result.concat(vm.data.lines)
                }
            });
        }

        $scope.productDelete = function(index) {
                vm.data.lines.splice(index, 1);
        }

        $scope.search = function(index) {
            if(vm.keyword == '' || vm.keyword == null ){
                vm.keyword = ''
                return
            }
            vm.searchSkuNumer = vm.data.lines.findIndex(function(item){
                return item.skuNumber == vm.keyword
            })
            var dom = document.getElementsByClassName('productItem-' + vm.searchSkuNumer)
            var contentDom = document.getElementsByClassName('shell-content scrollbar-main')
            if(!dom.length || !vm.keyword) return
            contentDom[0].scrollTop = dom[0].offsetTop
        }

        $scope.reset = function(index) {
            vm.keyword= ''
            vm.searchSkuNumer = null
            var contentDom = document.getElementsByClassName('shell-content scrollbar-main')
            contentDom[0].scrollTop = 0
        }

        $scope.save = function(submit){

            if(vm.isSaving){
                return
            }

           if(submit){
            var unSetQuantity = vm.data.lines.findIndex(function(item){
                return item.quantity == '' || item.quantity == undefined
            })
            if(unSetQuantity>-1){
                MessageService.error('有商品未填写盘点数量，无法确认!');
                return
            }
           }

            vm.isSaving = true;
            if(submit){
                StockManagement.submit(vm.data, function(res){
                    vm.isSaving = false;
                    MessageService.success('盘点确认成功！');
                    $scope.clear()
                    },function(error){
                    vm.isSaving = false;
                    MessageService.error('盘点确认失败');
                });
            }else{
                if($stateParams.id){
                    vm.data.lines.forEach(function(item){
                        item.stockTakingOrderId = $stateParams.id
                    })
                    StockManagement.update(vm.data, function(res){
                        vm.isSaving = false;
                        MessageService.success('保存盘点成功');
                        $scope.clear()
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('保存盘点失败');
                    });
                }else{
                    StockManagement.create(vm.data, function(res){
                        vm.isSaving = false;
                        MessageService.success('保存盘点成功');
                        $scope.clear()
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('保存盘点失败');
                    });
                }
            }
        }

        $scope.clear = function() {
            history.back();
        }

        //关闭
        $scope.close = function (){
            MessageService.confirm("确认关闭当前盘点", function () {
                StockManagement.close({id:vm.data.id}, function(res){
                    MessageService.success('关闭成功');
                    $scope.clear()
                },function(error){
                    MessageService.error('拒绝失败');
                });
            });
        }

        $scope.getStatus = function(item){
            switch (item) {
                case 'INPROGRESS':
                    return '盘点中';
                case 'FINISHED':
                    return '已完成';
                case 'COLSED':
                    return '已关闭';
                default:
                    return item
            }
        }
    }
})();
