(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('StockController', StockController);

    StockController.$inject = ['$scope', 'Principal', '$state', 'StockManagement', 'MessageService', 'PurchaseOrder', 'ParseLinks', '$uibModal', '$timeout', '$stateParams',
        'Supplier','Depository','DateUtils'];

    function StockController ($scope, Principal, $state, StockManagement, MessageService, PurchaseOrder, ParseLinks, $uibModal, $timeout, $stateParams,
                                 Supplier,Depository,DateUtils) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_STOCK_TAKING_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问的权限！");
                $state.go("home");
            }
        });

        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.selection = [
            {name:'全部', value:''},
            {name:'盘点中', value:'INPROGRESS'},
            {name:'已完成', value:'FINISHED'},
            {name:'已关闭', value:'COLSED'}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            type:''
        };
        vm.suppliers = Supplier.getAll();
        Depository.getAll({},function(res){
            vm.warehouses = res.filter(function(item){return item.defaulted && item.houseSource === 'Internal'});
        });


        // 新建时打开选择仓库的弹框
        $scope.openChooseWarehouseDialog = function(record) {
            $uibModal.open({
                templateUrl: 'app/stockManagement/components/choose-warehouse-dialog.html',
                controller: 'ChooseWarehouseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                }
            }).result.then(function(result){
            });
        };

        init();
        function init() {
            vm.tableList = [];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            var items = [];
            var _p = {
                items:items,
                page:vm.tableData.page,
                size:vm.tableData.size,
                sort:vm.tableData.sort,
            }
            if(vm.tableData.type){
                items.push({key:"status",op:"=",value:vm.tableData.type})
            }
            if(vm.tableData.number){
                items.push({key:"number",op:"=",value:vm.tableData.number})
            }
            if(vm.tableData.supplierId){
                items.push({key:"supplier.id",op:"=",value:vm.tableData.supplierId})
            }
            if(vm.tableData.warehouseId){
                items.push({key:"warehouse.id",op:"=",value:vm.tableData.warehouseId})
            }
            if(vm.tableData.startDate){
                items.push({
                    "key": "createdDate",
                    "op": ">=",
                    "value": DateUtils.dayStart(vm.tableData.startDate)
                })
            }
            if(vm.tableData.endDate){
                items.push({
                    "key": "createdDate",
                    "op": "<=",
                    "value": DateUtils.dayEnd(vm.tableData.endDate)
                })
            }
            _p.items = items;
            StockManagement.byCondition(_p, function (res, headers) {
                // console.log(res)
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                // laypage({
                //     cont: 'paging',
                //     curr: (vm.tableData.page + 1), //初始化当前页
                //     pages: pages,
                //     skip: true, //是否开启跳页
                //     first: false,
                //     last: false,
                //     limit: true,
                //     jump: function(obj, first){
                //         if (!first) {
                //             vm.tableData.page = obj.curr - 1;
                //             init();
                //         }
                //     }
                // });
            }, function (error) {
                console.log(error)
            });
        }

        $scope.conditionSelect = function(data){
            vm.tableData.type = data.value;
            vm.tableData.page = 0;
            init();
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.reset = function(){
            vm.tableData = {
                page: Number($stateParams.page) || 0,
                size: 20,
                sort: ['createdDate,DESC'],
                type:''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
        };

        $scope.tableStateBtn = function () {
            var _h = angular.element(".table-top").height();
            if(_h > 40){
                angular.element(".table-top").height("40");
                angular.element(".table-state-btn").removeClass("table-state-btnOn");
            }else {
                angular.element(".table-top").height("auto");
                angular.element(".table-state-btn").addClass("table-state-btnOn");
            }
        };

        window.onresize = function(){
            var _h = angular.element(".table-top").find(".table-state").height();
            $timeout(function () {
                if(_h > 40){
                    vm.isTableState = true;
                }else {
                    vm.isTableState = false;
                }
            })
        }


        $scope.purchaseOrderType = function (item){
            switch (item) {
                case 'INPROGRESS':
                    return {
                        text:'盘点中',
                        color:'#333'
                    };
                case 'FINISHED':
                    return {
                        text:'已完成',
                        color:'#005eff'
                    };
                case 'COLSED':
                    return {
                        text:'已关闭',
                        color:'#333'
                    };
                default:
                    return {
                        text:item,
                        color:'#333'
                    };
            }
        }
    }

})();
