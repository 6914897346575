(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ModuleController', ModuleController);

    ModuleController.$inject = ['$scope','Auth', 'Principal', '$uibModal', 'LoginService', '$state','Module','SideBarService', '$sessionStorage','$timeout','Notices', 'MessageService'];

    function ModuleController ($scope, Auth,Principal, $uibModal, LoginService, $state,Module,SideBarService, $sessionStorage,$timeout,Notices, MessageService) {
        var vm = this;
        vm.modules = Module;
        vm.hide = hide;
        vm.check = check;
        vm.logout = logout;
        vm.jzMessage = [];
        vm.noticeModule = noticeModule;
        vm.user = $sessionStorage.user;
        vm.jzMessageShow = false;
        vm.unReadCount = 0;
        vm.getUnReadCount = getUnReadCount;
        // console.log(vm.user);

        SideBarService.sort();

        init();
        function init() {
            Notices.unRead({
                keyword:'',
                read:false,
                page: 0,
                size: 10,
                sort: "id,DESC",
            }, function (res) {
                vm.jzMessage = res;
            })
        }
        getUnReadCount();
        function getUnReadCount(){
            return Notices.getUnReadCount({}, function (res) {
                vm.unReadCount = res.count;
            })
        }

        vm.stompClient = null;
        var sockjs_url = '/scm/ws';
        function connect() {
            var socket = new SockJS(sockjs_url);//连接服务端的端点，连接以后才可以订阅广播消息和个人消息
            vm.stompClient = Stomp.over(socket);
            vm.stompClient.connect({}, function(frame) {
                vm.stompClient.subscribe('/topic/notices', function(greeting){
                    var body = JSON.parse(greeting.body)
                    vm.unReadCount = body ? body.count : 0;
                    $scope.$apply();
                });
            });
        }
        connect()

        $scope.jzMessageOver = function(e){
            if(!vm.jzMessageShow){
                vm.jzMessageShow = true;
                init();
            }
        },
        $scope.jzMessageLeave = function(){
            vm.jzMessageShow = false;
        },
        $scope.$on('moduleInit', function () {
            init();
        });

        $scope.navSelect = function (nav,outerIndex) {
            $state.go(nav.id,nav.parameter);
            if(vm.navId == nav.id){
                return
            }
            angular.element('.sub_menu').removeClass('navOn');
            vm.navId = nav.id;
            sessionStorage.setItem('subId',nav.id);//二级菜单的id
            sessionStorage.setItem('outerIndex',outerIndex);//一级菜单index
            sessionStorage.removeItem('tableData');
        };


        //等待页面加载完成之后 自执行
        vm.subId = sessionStorage.getItem('subId');
        vm.outerIndex = sessionStorage.getItem('outerIndex');
        if(vm.subId && vm.outerIndex) {
            $timeout(function () {
                angular.element('#z' + vm.outerIndex).trigger('click');
                angular.element('#' + vm.subId).addClass('navOn');
            }, 100);
        }

        //列表收缩 展开
        $(document).on('click', '.list-menu', function(){
            if(!$(this).hasClass('on')){
                $(this).next('.shell-nav-body').slideDown(200).parent().siblings('li').find('.shell-nav-body').slideUp(200);
                $(this).addClass('on').parent().siblings('li').find('.list-menu').removeClass('on');
                $(this).find('.shell-nav-head-fr-show').addClass('rotateIcon').end().parent().siblings('li').find('.shell-nav-head-fr-show').removeClass('rotateIcon');
            }else{
                $(this).removeClass('on');
                $(this).next('.shell-nav-body').slideUp(200).parent().siblings('li').find('.shell-nav-body').slideUp(200);
                $(this).find('.shell-nav-head-fr-show').removeClass('rotateIcon');
            }
        });

        function hide(){
            var wSize = $(window).width();
            if(wSize<768){
                vm.click = true;
            }
        }

        function check(){
            var wSize = $(window).width();
            if(wSize<768){
                if(vm.click){
                    $('#sidebar').removeClass("test");
                    vm.click = false;
                }
            }
            if(wSize>768){
                $('#sidebar').removeClass("test");
                vm.click = false;
            }
        }

        function logout(){
            vm.stompClient.disconnect();
            Auth.logout();
            $state.go("login");
            sessionStorage.removeItem('subId');
            sessionStorage.removeItem('outerIndex');
            sessionStorage.removeItem('tableData');
        }

        //重置密码
        $scope.modifyContent = function(){
            $uibModal.open({
                templateUrl: 'app/user/user-password.html',
                controller: 'UserPasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supplier');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return vm.user;
                    }
                }
            }).result.then(function(result){
                if(result){
                    logout();
                }
            });
        };

        Principal.identity().then(function(data) {
        	vm.auth=data.authorities;
        	if(vm.auth.length>0){
        		for(var i=0;i<vm.auth.length;i++){
        			if(vm.modules.length>0){
        				for(var j=0;j<vm.modules.length;j++){
        					if(vm.modules[j].auth.length>0&&vm.modules[j].auth.indexOf(vm.auth[i])>-1){
        						vm.modules[j].visiable = true;
        						if(vm.modules[j].menus.length>0){
        							for(var k=0;k<vm.modules[j].menus.length;k++){
        								if(vm.modules[j].menus[k].auth.length>0&&vm.modules[j].menus[k].auth.indexOf(vm.auth[i])>-1){
        									vm.modules[j].menus[k].visiable = true;
        								}
        							}
        						}
        					}
        				}
        			}
        		}
        	}
        });

        //标记已读
        $scope.read = function (data) {
            Notices.read({id:data.id}, function (res) {
                MessageService.success('已读');
                init();
            })
        };
        //全部已读
        $scope.readBatch = function () {
            var _ids = [];
            if(vm.jzMessage.length> 0){
                for(var i=0; i<vm.jzMessage.length; i++){
                    _ids.push(vm.jzMessage[i].id)
                }
                Notices.readBatch(_ids, function (res) {
                    MessageService.success('已读');
                    init();
                })
            }
        };
        function noticeModule(item){
            switch(item) {
                case 'Team':
                    return '团队'
                case 'Order':
                    return '订单'
                case 'InquiryOrder':
                    return '询价单'
                case 'Information':
                    return '案例'
                default:
                    return ''
            }
        }
    }
})();
