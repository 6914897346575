(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('WarehousingController', WarehousingController);

    WarehousingController.$inject = ['$scope','$state', 'msgdata', '$uibModal', '$uibModalInstance', 'MessageService', 'PurchaseOrder'];

    function WarehousingController ($scope, $state, msgdata, $uibModal, $uibModalInstance, MessageService, PurchaseOrder) {
        var vm = this;
        vm.purchaseOrderList = msgdata.purchaseOrderList;
        vm.purchaseOrder = msgdata.purchaseOrder;

        $scope.saveNum = function(){
            if(vm.warehousingNum > 0){
                PurchaseOrder.changeWarehousingNum({
                    id: vm.purchaseOrder.id,
                    lineId: vm.purchaseOrderList.id,
                    num: vm.warehousingNum
                },function(res){
                    MessageService.success('入库成功！');
                    $uibModalInstance.close(res);
                },function(error){
                    MessageService.error('入库失败');
                });
            }else{
                MessageService.error('请输入入库数量');
            }
        };

        $scope.cancelTask = function(){
            $uibModalInstance.close();
        };

    }
})();
